import { useContext } from "react";
import Modal from "react-bootstrap/Modal";
import DataContext from "../Elements/eventContext";
import constant from "../Services/constant";

const SizeChartModal = ({ sizechartdata, sizechartimgpath }) => {

    const contextValues = useContext(DataContext)
    const sizechartModal = () => {
        contextValues.setToggleSizeChartModal(!contextValues.toggleSizeChartModal)
    }
    return (<>
        <Modal show={contextValues.toggleSizeChartModal} onHide={(e) => sizechartModal()} className="lrpop">

            <button onClick={(e) => sizechartModal()} className="pop-close "><i className="d-icon-times"></i></button>
            <Modal.Body>
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="text-center">

                                <img src={sizechartdata.size_chart_image ? sizechartimgpath + sizechartdata.size_chart_image : constant.DEFAULT_IMAGE} style={{ width: '100%', height: '100%' }}></img>

                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>

    </>)
}

export default SizeChartModal