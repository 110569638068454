import React, { useEffect, useState, useContext } from "react";
import constant from "../../Components/Services/constant";
import { ApiService } from "../Services/apiservices";
import { toast } from "react-toastify";
import multiCurrency from "./multi_currrency";
import StarRating from "./starrating";
import DataContext from "./eventContext";
function ProductBox({ productValue, indexProduct, classType }) {
    const contextValues = useContext(DataContext);
    const [setSession, SetSession] = useState(localStorage.getItem("USER_TOKEN")); 
    const quickviewModal = (data) => {
        contextValues.setProductData(data)
        setTimeout(()=>{
            contextValues.setToggleQuickViewModal(!contextValues.toggleQuickViewModal)
        },100)
    } 
    const resetFilter = () => {
        localStorage.removeItem('collection')
        localStorage.removeItem('category')
        localStorage.removeItem('tag')
        localStorage.removeItem('FILTER_SESSION')
        localStorage.removeItem('SORT_SESSION')
        localStorage.removeItem('RANGE_SESSION')
        // window.location.reload()
    }

    const addtofav = (productId) => {
        const dataString = {
            product_id: productId,
        };
        ApiService.postData("add-to-fav", dataString).then((res) => {
            if (res.data.status == "success") {
                var element = document.getElementById("wishlisticon" + productId);
                element.classList.remove("d-icon-heart", "d-icon-heart-full");
                element.classList.add(res.data.notification);
                if (res.data.notification === "d-icon-heart") {
                    toast.success("Removed from wishlist");
                } else {
                    toast.success("Added to Wishlist");
                }
            }
        });
    };
    let mrpValue = parseFloat(productValue.product_price);
    let sellingPriceValue = parseFloat(
        productValue.product_selling_price
    );
    let discount = 0;
    if (!isNaN(mrpValue) && !isNaN(sellingPriceValue)) {
        discount = (((mrpValue - sellingPriceValue) / mrpValue) * 100);
    } else {
        discount = 0;
    }
    const loginModal = () => {
        contextValues.setToggleLoginModal(!contextValues.toggleLoginModal)
      } 
    return (
        <><div className={classType} key={indexProduct}>
            <figure className="product-media">
                <a className="product-media-img" href={"/product/" + productValue.product_slug}
                    onClick={() => resetFilter()}
                >
                    {productValue.product_type === 0 ? (
                        productValue.product_inventory === 1 ? (
                            productValue.product_stock === 0 ? (
                                productValue.product_backorder === 0 ||
                                    productValue.product_backorder === 1 ? (
                                    <div className="outofstox mb-0">
                                        Out of Stock
                                    </div>
                                ) : null
                            ) : null
                        ) : null
                    ) : productValue.product_stock === 0 ? (
                        <div className="outofstox mb-0">
                   
                            Out of Stock
                        </div>
                    ) : null}
                    <img
                        src={
                            productValue.product_image != null
                                ? productValue.product_image
                                : constant.DEFAULT_IMAGE
                        }
                        alt={productValue.product_name}
                        width="280"
                        height="315"
                        style={{position:'unset' , opacity:1}}
                    />
                    {productValue.gallery && productValue.gallery.length > 0 ? (
                        <img
                            src={
                                productValue.gallery[0].gallery_image
                                    ? productValue.gallery[0].gallery_image
                                    : constant.DEFAULT_IMAGE
                            }
                            alt={productValue.product_name}
                            width="280"
                            height="315"
                        />
                    ) : null}
                </a>
                <div className="product-action-vertical">
                    {setSession ? (
                        productValue.ufp_id > 0 ? (
                            <a
                                href="javascript:void(0)"
                                className="btn-product-icon btn-wishlist"
                                title="Add to wishlists"
                                onClick={(e) =>
                                    addtofav(productValue.product_id)
                                }
                            >
                                <i
                                    className="d-icon-heart-full"
                                    id={
                                        "wishlisticon" +
                                        productValue.product_id
                                    }
                                ></i>
                            </a>
                        ) : (
                            <a
                                href="javascript:void(0)"
                                className="btn-product-icon btn-wishlist"
                                title="Add to wishlist"
                                onClick={(e) =>
                                    addtofav(productValue.product_id)
                                }
                            >
                                <i
                                    className="d-icon-heart"
                                    id={
                                        "wishlisticon" +
                                        productValue.product_id
                                    }
                                ></i>
                            </a>
                        )
                    ) : (
                        <a
                            href="javascript:void(0)"
                            className="btn-product-icon btn-wishlist"
                            title="Add to wishlist"
                            onClick={loginModal}
                        >
                            <i className="d-icon-heart"></i>
                        </a>
                    )}
                </div>
                <div className="product-action">
                    <a
                        href="javascript:void(0)"
                        className="btn-product btn-quickview"
                        title="Quick View"
                        onClick={() => {
                            quickviewModal(productValue);
                        }}
                    >
                        Quick View
                    </a>
                </div>
            </figure>
            <div className="product-details">
               
                    <h3 className="product-name">
                        <a
                            href={
                                "/product/" + productValue.product_slug
                            }
                            onClick={() => {
                                localStorage.removeItem('SORT_SESSION');
                                localStorage.removeItem('FILTER_SESSION');
                                localStorage.removeItem('RANGE_SESSION');
                            }}
                        >
                            {productValue.product_name}
                   
                        </a>
                    </h3>
                    <div className="product-price">
                        <ins className="new-price">
                            {multiCurrency(productValue.product_selling_price)}
                        </ins>
                        {discount > 0 ? (
                            <>
                                <del className="old-price">
                                    {multiCurrency(productValue.product_price)}
                                </del>
                                <span className="off">
                                    {Math.round(discount)}% Off
                                </span>
                            </>
                        ) : null}
                    </div>
                    {productValue.product_rating &&
                        productValue.product_rating > 0 ? (
                        <div className="ratings-container">
                            <StarRating
                                numberOfStars={
                                    productValue.product_rating
                                }
                            />
                            <span>
                                ( {productValue.product_review} reviews )
                            </span>
                        </div>
                    ) : (
                        ""
                    )}
              
            </div>
        </div></>
    );
}
export default ProductBox;