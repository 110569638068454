import React, { useEffect, useState, useRef, useContext } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SpinnerLoader from "../../Components/Elements/spinner_loader";
import { ApiService } from "../../Components/Services/apiservices";
import { useNavigate } from "react-router-dom";
import CartHeader from "../../Components/CartHeader";
import DataContext from "../../Components/Elements/eventContext";
import sessionCartData from "../../Components/Elements/cart_session_data";
import multiCurrency from "../../Components/Elements/multi_currrency";
import { validEmail, validNumber } from "../../Components/Elements/Regex";
import Loader from "react-js-loader";
import CheckoutAddressModal from "../../Components/Modals/checkout_address_modal";
import Collapse from 'react-bootstrap/Collapse';
import discountCurrency from "../../Components/Elements/discount_currency";

function CartAddress() {
    const didMountRef = useRef(true);
    const Navigate = useNavigate();
    const dataArray = sessionCartData();
    const sessionData = sessionCartData();
    const contextValues = useContext(DataContext)

    const [shippingAvailibilityStatus, setShippingAvailibilityStatus] = useState(false);

    const [spinnerLoading, setSpinnerLoading] = useState(true);
    const [hideShowLogin, setHideShowLogin] = useState(false);
    const [loginSpinnerLoading, setLoginSpinnerLoading] = useState(false);
    const [couponSpinnerLoading, setCouponSpinnerLoading] = useState(false);
    const [addAddressSpinnerLoading, setAddAddressSpinnerLoading] = useState(false);
    const [rowUserData, setRowUserData] = useState({});
    const [shippingCharge, setShippingCharge] = useState('');
    const [userLoginDetails, setUserLoginDetails] = useState({
        user_email: "",
        user_password: "",
        session_data: dataArray[1],
    });
    const [open, setOpen] = useState(false);
    const [emailFocused, setEmailFocused] = useState(false);
    const [passwordFocused, setPasswordFocused] = useState(false);

    const [firstnameFocused, setfirstnameFocused] = useState(false);
    const [lastnameFocused, setlastnameFocused] = useState(false);
    const [mobilenumberFocused, setmobilenumberFocused] = useState(false);
    const [addressFocused, setaddressFocused] = useState(false);
    const [postalFocused, setpostalFocused] = useState(false);
    const [stateFocused, setstateFocused] = useState(false);
    const [cityFocused, setcityFocused] = useState(false);
    const [countryFocused, setcountryFocused] = useState(false);
    const [addressTypeFocused, setaddressTypeFocused] = useState(false);
    const [addressTypeOtherFocused, setaddressTypeOtherFocused] = useState(false);

    const [billfirstnameFocused, setBillfirstnameFocused] = useState(false);
    const [billlastnameFocused, setBilllastnameFocused] = useState(false);
    const [billmobilenumberFocused, setBillmobilenumberFocused] = useState(false);
    const [billaddressFocused, setBilladdressFocused] = useState(false);
    const [billpostalFocused, setBillpostalFocused] = useState(false);
    const [billstateFocused, setBillstateFocused] = useState(false);
    const [billcityFocused, setBillcityFocused] = useState(false);
    const [billcountryFocused, setBillcountryFocused] = useState(false);
    const [userAddressDetails, setUserAddressDetails] = useState({
        ua_id: 0,
        ua_user_id: 0,
        ua_fname: "",
        ua_lname: "",
        ua_email: "",
        ua_mobile: "",
        ua_pincode: "",
        ua_house_no: "",
        ua_state_id: "",
        ua_city_id: "",
        ua_state_name: "",
        ua_city_name: "",
        ua_country_id: "13",
        ua_address_type: "",
        ua_address_type_other: "",
        ua_bill_fname: "",
        ua_bill_lname: "",
        ua_bill_email: "",
        ua_bill_mobile: "",
        ua_bill_pincode: "",
        ua_bill_house_no: "",
        ua_bill_state_id: "",
        ua_bill_city_id: "",
        ua_bill_state_name: "",
        ua_bill_city_name: "",
        ua_bill_country_id: "13",
        ua_billing_address_check: true
    });
    const [settingData, setSettingData] = useState({});
    const [settingImagePath, setSettingImagePath] = useState("");
    const [promoCode, setPromoCode] = useState({ promo_code: "", });
    useEffect(() => {
        if (didMountRef.current) {
            getSettingsData()
            contextValues.setAddressSession(sessionData[0])
            contextValues.setCouponSession(sessionData[2])
            contextValues.setUserToken(localStorage.getItem("USER_TOKEN"))
            if (localStorage.getItem("USER_TOKEN")) {
                getUserData()
                getUserAddress();
                cartSessionDataFetch();
            } else {
                if (sessionData[1] && sessionData[1].length > 0) {
                    contextValues.setCartCount(sessionData[1].length)
                    contextValues.setCartSessionData(sessionData[1])
                    contextValues.setCartSummary(sessionData[3])
                    const itemtotal = parseFloat(sessionData[3].itemTotal) - parseFloat(sessionData[3].discount) - parseFloat(sessionData[2] && sessionData[2].discount_amount ? sessionData[2].discount_amount : 0);
                    calculateShippingAmount(sessionData[1], itemtotal)
                } else {
                    Navigate("/");
                }
            }
            setTimeout(() => {
                setSpinnerLoading(false);
            }, 500);
        }
        didMountRef.current = false;
    }, [contextValues]);

    const cartSessionDataFetch = () => {
        const dataString = {
            product_id: '',
        };
        ApiService.postData("cartSessionData", dataString).then((res) => {
            if (res.data.status === "success") {
                if (res.data.resCartData && res.data.resCartData.length > 0) {
                    contextValues.setCartSessionData(res.data.resCartData)
                    contextValues.setCartCount(res.data.resCartData.length)
                    contextValues.setCartSummary(res.data.cartSummary)
                    const itemtotal = parseFloat(res.data.cartSummary.itemTotal) - parseFloat(res.data.cartSummary.discount) - parseFloat(sessionData[2] && sessionData[2].discount_amount ? sessionData[2].discount_amount : 0);
                    calculateShippingAmount(res.data.resCartData, itemtotal)
                } else {
                    Navigate("/");
                }
            }
        });
    }

    const getSettingsData = () => {
        ApiService.fetchData("settings").then((res) => {
            if (res.status === "success") {
                setSettingData(res.sitesettings);
                setSettingImagePath(res.setting_image_path)
            }
        });
    };

    const getUserData = () => {
        ApiService.fetchData("get-user-data").then((res) => {
            if (res.status == "success") {
                setRowUserData(res.rowUserData);
                setTimeout(() => {
                    setSpinnerLoading(false);
                }, 500);
            } else {
                setTimeout(() => {
                    setSpinnerLoading(false);
                }, 500);
            }
        });
    };

    const getUserAddress = () => {
        ApiService.fetchData("get-user-address").then((res) => {
            if (res.status === "success") {
                if (res.resAddressDefault && res.resAddressDefault.ua_id > 0) {
                    checkShippingAvailability(res.resAddressDefault.ua_pincode)
                    contextValues.setDefaultAddressStatus(true)
                } else {
                    contextValues.setDefaultAddressStatus(false)
                }
                contextValues.setUserAddressList(res.resUserAddress);
                setSpinnerLoading(false);
            } else {
                localStorage.removeItem("USER_TOKEN");
                setSpinnerLoading(false);
                Navigate("/");
            }
        });
    };

    const selectAddress = (addressValue) => {
        const productData = {
            ua_id: addressValue.ua_id,
        };
        ApiService.postData("chooseAddress", productData).then((res) => {
            if (res.status === "success") {
                contextValues.setDefaultAddressStatus(true)
                contextValues.setUserAddressList(res.resUserAddress);
                checkShippingAvailability(addressValue.ua_pincode)
                setTimeout(() => {
                    setSpinnerLoading(false)
                }, 500);
            } else {
                setTimeout(() => {
                    setSpinnerLoading(false)
                }, 500);
            }
        }).catch((error) => {

        });
    }

    const showLogin = () => {
        setHideShowLogin(!hideShowLogin)
    }

    const logoutUser = () => {
        ApiService.fetchData("logout-user").then((res) => {
            window.location.reload();
        });
    }

    const onTodoChange = (e) => {
        const { name, value } = e.target;
        setUserLoginDetails((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        if (name === 'user_email') {
            setUserAddressDetails((prevState) => ({
                ...prevState,
                ['ua_email']: value,
            }));
        }
    };

    const userLoginProcess = () => {
        let counter = 0;
        const myElements = document.getElementsByClassName("loginRequired");
        for (let i = 0; i < myElements.length; i++) {
            if (myElements[i].value === '') {
                myElements[i].style.border = '1px solid red';
                counter++;
            } else {
                myElements[i].style.border = '';
            }
        }

        if (counter === 0) {
            if (!validEmail.test(userLoginDetails.user_email)) {
                toast.error("Please enter valid Email Id");
                return false;
            }
            setLoginSpinnerLoading(true)
            ApiService.postData('user-login-process', userLoginDetails).then((res) => {
                if (res.status === "success") {
                    localStorage.setItem("USER_TOKEN", res.user_token)
                    toast.success(res.message)
                    setLoginSpinnerLoading(false)
                    localStorage.removeItem("COUPON_SESSION");
                    localStorage.removeItem("CART_SESSION");
                    window.location.reload();
                } else {
                    toast.error(res.message)
                    setLoginSpinnerLoading(false)
                }
            });
        }
    };

    const toggleCheckoutAddressModal = () => {
        contextValues.setToggleCheckoutAddressModal(!contextValues.toggleCheckoutAddressModal)
    }

    const onTodoChangePromo = (e) => {
        const { name, value } = e.target;
        setPromoCode((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const removeCoupon = () => {
        localStorage.removeItem("COUPON_SESSION");
        const couponSession = localStorage.getItem("COUPON_SESSION");
        const parsedCouponSession = couponSession ? JSON.parse(couponSession) : {
            discount_amount: 0.0,
            promo_id: 0,
            promo_code: "",
        };
        contextValues.setCouponSession(parsedCouponSession)
    }

    const applyCouponTyping = () => {
        if (promoCode.promo_code == '') {
            toast.error("Please enter Coupon Code");
            return false;
        }
        const productData = {
            promo_code: promoCode.promo_code,
            cart_total: contextValues.cartSummary && contextValues.cartSummary.sellingTotal ? contextValues.cartSummary.sellingTotal : 0,
        };
        setCouponSpinnerLoading(true)
        ApiService.postData("select-coupon", productData).then((res) => {
            if (res.data.status === 'success') {
                toast.success(res.data.notification)
                localStorage.removeItem("COUPON_SESSION");
                const couponSessionObj = {
                    discount_amount: res.data.discount_amount,
                    promo_id: res.data.promo_id,
                    promo_code: res.data.promo_code,
                };
                localStorage.setItem("COUPON_SESSION", JSON.stringify(couponSessionObj));
                contextValues.setCouponSession(couponSessionObj);
                //window.location.reload();
                setCouponSpinnerLoading(false)
                setPromoCode((prevState) => ({
                    ...prevState,
                    ['promo_code']: '',
                }));
            } else {
                toast.error(res.data.notification)
                setCouponSpinnerLoading(false)
            }
        }).catch((error) => {
            toast.error(error)
            setCouponSpinnerLoading(false)
        });
    }

    const onTodoRegChange = (e) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : value;
        setUserAddressDetails((prevState) => ({
            ...prevState,
            ['ua_country_id']: 13,
            [name]: newValue,
        }));
        if (name === 'ua_pincode' || name === 'ua_bill_pincode') {
            if (value.length === 4) {
                checkShippingAvailability(value)
            }
        }
    }

    const checkShippingAvailability = (pincode) => {
        const dataString = {
            ua_pincode: pincode
        }
        ApiService.postData("check-shipping-availability", dataString).then((res) => {
            if (res.status === "success") {
                setShippingAvailibilityStatus(res.shipping)
            } else {
                setShippingAvailibilityStatus(res.shipping)
                toast.error(res.notification);
            }
        });
    };

    const handleContinueProcess = () => {
        if (parseFloat(contextValues.cartSummary.itemTotal) - parseFloat(contextValues.cartSummary.discount) - parseFloat(contextValues.couponSession && contextValues.couponSession.discount_amount ? contextValues.couponSession.discount_amount : 0) <= settingData.admin_min_order) {
            toast.error("Minimum Order value AUD $" + settingData.admin_min_order);
            return false;
        }
        if (localStorage.getItem("USER_TOKEN")) {
            if (contextValues.userAddressList.length == 0) {
                if (userAddressDetails.ua_fname === "") {
                    toast.error("Please enter First Name");
                    return false;
                }
                if (userAddressDetails.ua_lname === "") {
                    toast.error("Please enter Last Name");
                    return false;
                }
                if (!validNumber.test(userAddressDetails.ua_mobile)) {
                    toast.error("Please enter valid Mobile Number");
                    return false;
                }

                if (userAddressDetails.ua_house_no === "") {
                    toast.error("Please enter Address (Villa/Apt#, Building Name & Street)");
                    return false;
                }
                if (userAddressDetails.ua_pincode === "") {
                    toast.error("Please enter Pincode");
                    return false;
                }
                if (userAddressDetails.ua_state_name === "") {
                    toast.error("Please enter State Name");
                    return false;
                }
                if (userAddressDetails.ua_city_name === "") {
                    toast.error("Please enter City Name");
                    return false;
                }
                if (userAddressDetails.ua_address_type === "") {
                    toast.error("Please select Address type");
                    return false;
                }
                if (userAddressDetails.ua_address_type === "Other") {
                    if (userAddressDetails.ua_address_type_other === "") {
                        toast.error("Please enter Other");
                        return false;
                    }
                }
                if (!userAddressDetails.ua_billing_address_check) {
                    if (userAddressDetails.ua_bill_fname === "") {
                        toast.error("Please enter First Name");
                        return false;
                    }
                    if (userAddressDetails.ua_bill_lname === "") {
                        toast.error("Please enter Last Name");
                        return false;
                    }
                    if (!validNumber.test(userAddressDetails.ua_bill_mobile)) {
                        toast.error("Please enter valid Mobile Number");
                        return false;
                    }
                    if (userAddressDetails.ua_bill_house_no === "") {
                        toast.error("Please enter Address (Villa/Apt#, Building Name & Street)");
                        return false;
                    }
                    if (userAddressDetails.ua_bill_pincode === "") {
                        toast.error("Please enter Pincode");
                        return false;
                    }
                    if (userAddressDetails.ua_bill_state_name === "") {
                        toast.error("Please enter State Name");
                        return false;
                    }
                    if (userAddressDetails.ua_bill_city_name === "") {
                        toast.error("Please enter City Name");
                        return false;
                    }
                }
                if (!shippingAvailibilityStatus) {
                    toast.error("Sorry, we do not ship to this address. Try another one.");
                    return false;
                }
                setAddAddressSpinnerLoading(true)
                ApiService.postData("user-address-process", userAddressDetails).then((res) => {
                    if (res.status == "success") {
                        setTimeout(() => {
                            setAddAddressSpinnerLoading(false)
                            Navigate("/checkout");
                        }, 500);
                    } else {
                        setAddAddressSpinnerLoading(false)
                        toast.error(res.message);
                    }
                }
                );
            } else {
                if (!shippingAvailibilityStatus) {
                    toast.error("Sorry, we do not ship to this address. Try another one.");
                    return false;
                }
                Navigate("/checkout");
            }
        } else {
            if (userLoginDetails.user_email === '') {
                toast.error("Please enter Email Address");
                return false;
            }
            if (!validEmail.test(userLoginDetails.user_email)) {
                toast.error("Please enter valid Email Address");
                return false;
            }
            if (userAddressDetails.ua_fname === "") {
                toast.error("Please enter First Name");
                return false;
            }

            if (userAddressDetails.ua_lname === "") {
                toast.error("Please enter Last Name");
                return false;
            }

            if (!validNumber.test(userAddressDetails.ua_mobile)) {
                toast.error("Please enter valid Mobile Number");
                return false;
            }

            if (userAddressDetails.ua_house_no === "") {
                toast.error("Please enter Address (Villa/Apt#, Building Name & Street)");
                return false;
            }
            if (userAddressDetails.ua_pincode === "") {
                toast.error("Please enter Pincode");
                return false;
            }
            if (userAddressDetails.ua_state_name === "") {
                toast.error("Please enter State Name");
                return false;
            }
            if (userAddressDetails.ua_city_name === "") {
                toast.error("Please enter City Name");
                return false;
            }

            if (userAddressDetails.ua_address_type === "") {
                toast.error("Please select Address type");
                return false;
            }

            if (userAddressDetails.ua_address_type === "Other") {
                if (userAddressDetails.ua_address_type_other === "") {
                    toast.error("Please enter Other");
                    return false;
                }
            }

            if (!userAddressDetails.ua_billing_address_check) {
                if (userAddressDetails.ua_bill_fname === "") {
                    toast.error("Please enter First Name");
                    return false;
                }

                if (userAddressDetails.ua_bill_lname === "") {
                    toast.error("Please enter Last Name");
                    return false;
                }

                if (!validNumber.test(userAddressDetails.ua_bill_mobile)) {
                    toast.error("Please enter valid Mobile Number");
                    return false;
                }

                if (userAddressDetails.ua_bill_house_no === "") {
                    toast.error("Please enter Address (Villa/Apt#, Building Name & Street)");
                    return false;
                }
                if (userAddressDetails.ua_bill_pincode === "") {
                    toast.error("Please enter Pincode");
                    return false;
                }
                if (userAddressDetails.ua_bill_state_name === "") {
                    toast.error("Please enter State Name");
                    return false;
                }
                if (userAddressDetails.ua_bill_city_name === "") {
                    toast.error("Please enter City Name");
                    return false;
                }
            }

            if (shippingAvailibilityStatus) {
                const productData = {
                    user_email: userLoginDetails.user_email,
                    user_address: userAddressDetails,
                    shipping_amount: shippingCharge,
                };
                localStorage.setItem("GUEST_CHECKOUT", JSON.stringify(productData));
                Navigate("/checkout");
            } else {
                toast.error("Sorry, we do not ship to this address. Try another one.");
                return false;
            }
        }
    }

    const calculateShippingAmount = (cartSession, amount) => {
        const dataString = {
            itemtotal: amount,
            cart_data: cartSession,
        };
        ApiService.postData("calculate-shipping-amount", dataString).then((res) => {
            if (res.status === "success") {
                setShippingCharge(res.shipping_amount);
            }
        });
    };

    return (
        <>
            <CartHeader />
            {spinnerLoading ? <SpinnerLoader /> :
                <>
                    <BrowserView>
                        {contextValues && contextValues.cartSessionData && contextValues.cartSessionData.length > 0 ?
                            <div className="cartSection">
                                <div className="container">
                                    <div className="left">
                                        <div className="p-30">
                                            {/* Login Section */}
                                            {contextValues.userToken ?
                                                <div className="row g-3 mb-30">
                                                    <div className="col-lg-12">
                                                        <div className="cartSectionTitle">
                                                            <h5 className="mb-0 tx-18">Account</h5>
                                                            <div><a href="javascript:void(0);" className="tx-primary" onClick={(e) => logoutUser()}>Log Out</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="pb-10" style={{ borderBottom: '1px solid #ddd' }}>
                                                            <h6 className="mb-0">{rowUserData.user_fname}</h6>
                                                            <p className="mb-0 tx-13">{rowUserData.user_email}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div className="row g-3 mb-30">
                                                    <div className="col-lg-12">
                                                        <div className="cartSectionTitle">
                                                            <h5 className="mb-0 tx-18">Customer information</h5>
                                                            <div>
                                                                Have an account? <a href="javascript:void(0);" className="tx-primary" onClick={(e) => showLogin()}>Login</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {hideShowLogin ?
                                                        <>
                                                            <div className="col-lg-5">
                                                                <div className="flotting-group mb-10">
                                                                    <label className={emailFocused || userLoginDetails.user_email ? "label" : ""}>Email Address</label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control loginRequired"
                                                                        placeholder="Email Address"
                                                                        name="user_email"
                                                                        value={userLoginDetails.user_email}
                                                                        onChange={(e) => onTodoChange(e)}
                                                                        onFocus={(e) => setEmailFocused(true)}
                                                                        onBlur={(e) => setEmailFocused(false)}></input>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-5">
                                                                <div className="flotting-group">
                                                                    <label className={passwordFocused || userLoginDetails.user_password ? "label" : ""}>Password</label>
                                                                    <input
                                                                        type="password"
                                                                        className="form-control loginRequired"
                                                                        placeholder="Password"
                                                                        name="user_password"
                                                                        value={userLoginDetails.user_password}
                                                                        onChange={(e) => onTodoChange(e)}
                                                                        onFocus={(e) => setPasswordFocused(true)}
                                                                        onBlur={(e) => setPasswordFocused(false)}></input>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2">
                                                                <button className="btn btn-primary btn-block" style={{ padding: '12px' }} type="button" onClick={(e) => userLoginProcess()} >{loginSpinnerLoading ? <Loader type="spinner-cub" bgColor={'#fff'} color={'#fff'} size={12} /> : <> Login</>}</button>
                                                            </div>
                                                        </>
                                                        :
                                                        <>
                                                            <div className="col-lg-12">
                                                                <div className="flotting-group mb-10">
                                                                    <label className={emailFocused || userLoginDetails.user_email ? "label" : ""}>Email Address</label>
                                                                    <input type="email" className="guestloginRequired form-control" name='user_email' placeholder="Email Address" value={userLoginDetails.user_email} onChange={(e) => onTodoChange(e)} onFocus={(e) => setEmailFocused(true)}
                                                                        onBlur={(e) => setEmailFocused(false)}></input>
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                            }
                                            {contextValues.userToken ?
                                                <>
                                                    {/* Address Section */}
                                                    {contextValues.userAddressList.length > 0 ?
                                                        <>
                                                            <div className="col-lg-12">
                                                                <div className="cartSectionTitle">
                                                                    <h5 className="mb-0 tx-18">Shipping Details</h5>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <div className="row">
                                                                    {contextValues.userAddressList.length > 0 && contextValues.userAddressList.map((value, index) => (
                                                                        <div className="col-lg-6">
                                                                            <div className={`cartAddressbox mt-20 ${value.ua_default_address == 1 ? 'active' : ''}`} key={index}
                                                                                onClick={(e) => selectAddress(value)}>
                                                                                <input type="radio" checked={value.ua_default_address == 1 ? true : false}></input>
                                                                                <h6 className="mb-0 tx-14">{value.ua_fname} {value.ua_lname}</h6>
                                                                                <p className="mb-1 tx-12">{value.ua_house_no}, {value.ua_city_name}, {value.ua_state_name}, {value.ua_pincode}</p>
                                                                                <p className="tx-gray mb-0 tx-12">Mobile No: {value.ua_mobile}</p>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                                <button type="button" className="btn btn-primary-outline btn-medium d-flex align-items-center mb-50 mt-20" onClick={(e) => toggleCheckoutAddressModal()}><i className="ri-add-circle-line ri-lg mr-5"></i>Add New Address</button>
                                                            </div>
                                                        </>
                                                        :
                                                        <>
                                                            <div className="row g-3 mb-20">
                                                                <div className="col-lg-12">
                                                                    <div className="cartSectionTitle">
                                                                        <h5 className="mb-0 tx-18">Shipping Details</h5>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <div className="flotting-group mb-10">
                                                                        <label className={`${firstnameFocused || userAddressDetails.ua_fname ? "label" : ""} `}>First Name</label>
                                                                        <input type="text" className={` required form-control`}
                                                                            name='ua_fname' placeholder="First Name" onChange={(e) => onTodoRegChange(e)}
                                                                            onFocus={(e) => setfirstnameFocused(true)}
                                                                            onBlur={(e) => setfirstnameFocused(false)}
                                                                        ></input>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <div className="flotting-group mb-10">
                                                                        <label className={lastnameFocused || userAddressDetails.ua_lname ? "label" : ""}>Last Name</label>
                                                                        <input type="text" className="required form-control" placeholder="Last Name" name='ua_lname'
                                                                            onFocus={(e) => setlastnameFocused(true)}
                                                                            onBlur={(e) => setlastnameFocused(false)}
                                                                            onChange={(e) => onTodoRegChange(e)}></input>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <div className="flotting-group mb-10">
                                                                        <label className={mobilenumberFocused || userAddressDetails.ua_mobile ? "label" : ""}>Mobile Number</label>
                                                                        <input type="number" className="required form-control" name="ua_mobile" placeholder="Mobile Number"
                                                                            onChange={(e) => onTodoRegChange(e)}
                                                                            onFocus={(e) => setmobilenumberFocused(true)}
                                                                            onBlur={(e) => setmobilenumberFocused(false)}></input>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8">
                                                                    <div className="flotting-group mb-10">
                                                                        <label className={addressFocused || userAddressDetails.ua_house_no ? "label" : ""}>Address</label>
                                                                        <input type="text" className="required form-control" name="ua_house_no"
                                                                            placeholder="Address (Villa/Apt#, Building Name & Street)"
                                                                            onFocus={(e) => setaddressFocused(true)}
                                                                            onBlur={(e) => setaddressFocused(false)}
                                                                            onChange={(e) => onTodoRegChange(e)}></input>
                                                                    </div>
                                                                </div>
                                                           
                                                                <div className="col-lg-4">
                                                                    <div className="flotting-group mb-10">
                                                                        <label className={cityFocused || userAddressDetails.ua_city_name ? "label" : ""}>City</label>
                                                                        <input type="text" className=" required form-control" name="ua_city_name"
                                                                            onChange={(e) => onTodoRegChange(e)}
                                                                            placeholder="City"
                                                                            onFocus={(e) => setcityFocused(true)}
                                                                            onBlur={(e) => setcityFocused(false)}
                                                                        ></input>
                                                                    </div>
                                                                </div>


                                                                <div className="col-lg-4">
                                                                    <div className="flotting-group mb-10">
                                                                        <label className={countryFocused || userAddressDetails.ua_country_id ? "label" : ""}>Country</label>
                                                                        <input type="text" className="required form-control" name="ua_country_id" disabled={true}
                                                                            value={'Australia'}
                                                                            onFocus={(e) => setcountryFocused(true)}
                                                                            onBlur={(e) => setcountryFocused(false)}
                                                                            onChange={(e) => onTodoRegChange(e)}></input>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4">
                                                                    <div className="flotting-group mb-10">
                                                                        <label className={stateFocused || userAddressDetails.ua_state_name ? "label" : ""}>State</label>
                                                                        <input type="text" className="required form-control"
                                                                            name="ua_state_name"
                                                                            placeholder="State"
                                                                            onFocus={(e) => setstateFocused(true)}
                                                                            onBlur={(e) => setstateFocused(false)}
                                                                            onChange={(e) => onTodoRegChange(e)}></input>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4">
                                                                    <div className="flotting-group mb-10">
                                                                        <label className={postalFocused || userAddressDetails.ua_pincode ? "label" : ""}>Postal Code</label>
                                                                        <input type="number" className="required form-control"
                                                                            placeholder="Postal Code" name="ua_pincode"
                                                                            onFocus={(e) => setpostalFocused(true)}
                                                                            onBlur={(e) => setpostalFocused(false)}
                                                                            onChange={(e) => onTodoRegChange(e)}></input>
                                                                    </div>
                                                                </div>
                                                          
                                                                <div className={userAddressDetails.ua_address_type === 'Other' ? "col-lg-6" : "col-lg-12"}>
                                                                    <div className="flotting-group mb-10">
                                                                        <label className={addressTypeFocused || userAddressDetails.ua_address_type ? "label" : ""}>Address Type</label>
                                                                        <select name="ua_address_type" className="form-control required" value={userAddressDetails.ua_address_type}
                                                                            onChange={(e) => onTodoRegChange(e)}
                                                                            onFocus={(e) => setaddressTypeFocused(true)}
                                                                            onBlur={(e) => setaddressTypeFocused(false)}>
                                                                            <option value="">Address Type</option>
                                                                            <option value="Home">Home</option>
                                                                            <option value="Work">Work</option>
                                                                            <option value="Other">Other</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                {userAddressDetails.ua_address_type === 'Other' &&
                                                                    <div className='col-lg-6'>
                                                                        <div className="flotting-group mb-10">
                                                                            <label className={addressTypeOtherFocused || userAddressDetails.ua_address_type_other ? "label" : ""}>Other</label>
                                                                            <input type="text" name="ua_address_type_other" className="form-control required"
                                                                                value={userAddressDetails.ua_address_type_other}
                                                                                onChange={(e) => onTodoRegChange(e)}
                                                                                onFocus={(e) => setaddressTypeOtherFocused(true)}
                                                                                onBlur={(e) => setaddressTypeOtherFocused(false)}
                                                                                placeholder="Other"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                            <div className="row mb-40">
                                                                <div className="col-lg-12">
                                                                    <div className="flotting-group checkboxgroup">
                                                                        <input type="checkbox" name='ua_billing_address_check' onChange={(e) => onTodoRegChange(e)} checked={userAddressDetails.ua_billing_address_check}></input>
                                                                        <span>My billing address is same as shipping address</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {!userAddressDetails.ua_billing_address_check && (
                                                                <div className="row g-3 mb-20">
                                                                    <div className="col-lg-12">
                                                                        <div className="cartSectionTitle">
                                                                            <h5 className="mb-0 tx-18">Billing Details</h5>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6">
                                                                        <div className="flotting-group mb-10">
                                                                            <label className={`${billfirstnameFocused || userAddressDetails.ua_bill_fname ? "label" : ""} `}>First Name</label>
                                                                            <input type="text" className={` required form-control`}
                                                                                name='ua_bill_fname' placeholder="First Name" onChange={(e) => onTodoRegChange(e)}
                                                                                onFocus={(e) => setBillfirstnameFocused(true)}
                                                                                onBlur={(e) => setBillfirstnameFocused(false)}
                                                                            ></input>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6">
                                                                        <div className="flotting-group mb-10">
                                                                            <label className={billlastnameFocused || userAddressDetails.ua_bill_lname ? "label" : ""}>Last Name</label>
                                                                            <input type="text" className="required form-control" placeholder="Last Name" name='ua_bill_lname'
                                                                                onFocus={(e) => setBilllastnameFocused(true)}
                                                                                onBlur={(e) => setBilllastnameFocused(false)}
                                                                                onChange={(e) => onTodoRegChange(e)}></input>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-12">
                                                                        <div className="flotting-group mb-10">
                                                                            <label className={billmobilenumberFocused || userAddressDetails.ua_bill_mobile ? "label" : ""}>Mobile Number</label>
                                                                            <input type="number" className="required form-control" name="ua_bill_mobile" placeholder="Mobile Number"
                                                                                onChange={(e) => onTodoRegChange(e)}
                                                                                onFocus={(e) => setBillmobilenumberFocused(true)}
                                                                                onBlur={(e) => setBillmobilenumberFocused(false)}></input>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-8">
                                                                        <div className="flotting-group mb-10">
                                                                            <label className={billaddressFocused || userAddressDetails.ua_bill_house_no ? "label" : ""}>Address</label>
                                                                            <input type="text" className="required form-control" name="ua_bill_house_no"
                                                                                placeholder="Address (Villa/Apt#, Building Name & Street)"
                                                                                onFocus={(e) => setBilladdressFocused(true)}
                                                                                onBlur={(e) => setBilladdressFocused(false)}
                                                                                onChange={(e) => onTodoRegChange(e)}></input>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-4">
                                                                        <div className="flotting-group mb-10">
                                                                            <label className={billpostalFocused || userAddressDetails.ua_bill_pincode ? "label" : ""}>Postal Code</label>
                                                                            <input type="number" className="required form-control"
                                                                                placeholder="Postal Code" name="ua_bill_pincode"
                                                                                onFocus={(e) => setBillpostalFocused(true)}
                                                                                onBlur={(e) => setBillpostalFocused(false)}
                                                                                onChange={(e) => onTodoRegChange(e)}></input>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-4">
                                                                        <div className="flotting-group mb-10">
                                                                            <label className={billcountryFocused || userAddressDetails.ua_bill_country_id ? "label" : ""}>Country</label>

                                                                            <input type="text" className="required form-control" name="ua_bill_country_id" disabled={true}
                                                                                value={'Australia'}
                                                                                onFocus={(e) => setBillcountryFocused(true)}
                                                                                onBlur={(e) => setBillcountryFocused(false)}
                                                                                onChange={(e) => onTodoRegChange(e)}></input>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-4">
                                                                        <div className="flotting-group mb-10">
                                                                            <label className={billstateFocused || userAddressDetails.ua_bill_state_name ? "label" : ""}>State</label>
                                                                            <input type="text" className="required form-control"
                                                                                name="ua_bill_state_name"
                                                                                placeholder="State"
                                                                                onFocus={(e) => setBillstateFocused(true)}
                                                                                onBlur={(e) => setBillstateFocused(false)}
                                                                                onChange={(e) => onTodoRegChange(e)}></input>

                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-4">
                                                                        <div className="flotting-group mb-10">
                                                                            <label className={billcityFocused || userAddressDetails.ua_bill_city_name ? "label" : ""}>City</label>
                                                                            <input type="text" className=" required form-control" name="ua_bill_city_name"
                                                                                onChange={(e) => onTodoRegChange(e)}
                                                                                placeholder="City"
                                                                                onFocus={(e) => setBillcityFocused(true)}
                                                                                onBlur={(e) => setBillcityFocused(false)}
                                                                            ></input>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </>
                                                    }
                                                </>
                                                :
                                                <>
                                                    <div className="row g-3 mb-20">
                                                        <div className="col-lg-12">
                                                            <div className="cartSectionTitle">
                                                                <h5 className="mb-0 tx-18">Shipping Details</h5>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="flotting-group mb-10">
                                                                <label className={`${firstnameFocused || userAddressDetails.ua_fname ? "label" : ""} `}>First Name</label>
                                                                <input type="text" className={` required form-control`}
                                                                    name='ua_fname' placeholder="First Name" onChange={(e) => onTodoRegChange(e)}
                                                                    onFocus={(e) => setfirstnameFocused(true)}
                                                                    onBlur={(e) => setfirstnameFocused(false)}
                                                                ></input>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="flotting-group mb-10">
                                                                <label className={lastnameFocused || userAddressDetails.ua_lname ? "label" : ""}>Last Name</label>
                                                                <input type="text" className="required form-control" placeholder="Last Name" name='ua_lname'
                                                                    onFocus={(e) => setlastnameFocused(true)}
                                                                    onBlur={(e) => setlastnameFocused(false)}
                                                                    onChange={(e) => onTodoRegChange(e)}></input>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <div className="flotting-group mb-10">
                                                                <label className={mobilenumberFocused || userAddressDetails.ua_mobile ? "label" : ""}>Mobile Number</label>
                                                                <input type="number" className="required form-control" name="ua_mobile" placeholder="Mobile Number"
                                                                    onChange={(e) => onTodoRegChange(e)}
                                                                    onFocus={(e) => setmobilenumberFocused(true)}
                                                                    onBlur={(e) => setmobilenumberFocused(false)}></input>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-8">
                                                            <div className="flotting-group mb-10">
                                                                <label className={addressFocused || userAddressDetails.ua_house_no ? "label" : ""}>Address</label>
                                                                <input type="text" className="required form-control" name="ua_house_no"
                                                                    placeholder="Address (Villa/Apt#, Building Name & Street)"
                                                                    onFocus={(e) => setaddressFocused(true)}
                                                                    onBlur={(e) => setaddressFocused(false)}
                                                                    onChange={(e) => onTodoRegChange(e)}></input>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <div className="flotting-group mb-10">
                                                                <label className={cityFocused || userAddressDetails.ua_city_name ? "label" : ""}>City</label>
                                                                <input type="text" className=" required form-control" name="ua_city_name"
                                                                    onChange={(e) => onTodoRegChange(e)}
                                                                    placeholder="City"
                                                                    onFocus={(e) => setcityFocused(true)}
                                                                    onBlur={(e) => setcityFocused(false)}
                                                                ></input>
                                                            </div>
                                                        </div>


                                                        <div className="col-lg-4">
                                                            <div className="flotting-group mb-10">
                                                                <label className={countryFocused || userAddressDetails.ua_country_id ? "label" : ""}>Country</label>
                                                                <input type="text" className="required form-control" name="ua_country_id" disabled={true}
                                                                    value={'Australia'}
                                                                    onFocus={(e) => setcountryFocused(true)}
                                                                    onBlur={(e) => setcountryFocused(false)}
                                                                    onChange={(e) => onTodoRegChange(e)}></input>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <div className="flotting-group mb-10">
                                                                <label className={stateFocused || userAddressDetails.ua_state_name ? "label" : ""}>State</label>
                                                                <input type="text" className="required form-control"
                                                                    name="ua_state_name"
                                                                    placeholder="State"
                                                                    onFocus={(e) => setstateFocused(true)}
                                                                    onBlur={(e) => setstateFocused(false)}
                                                                    onChange={(e) => onTodoRegChange(e)}></input>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <div className="flotting-group mb-10">
                                                                <label className={postalFocused || userAddressDetails.ua_pincode ? "label" : ""}>Postal Code</label>
                                                                <input type="number" className="required form-control"
                                                                    placeholder="Postal Code" name="ua_pincode"
                                                                    onFocus={(e) => setpostalFocused(true)}
                                                                    onBlur={(e) => setpostalFocused(false)}
                                                                    onChange={(e) => onTodoRegChange(e)}></input>
                                                            </div>
                                                        </div>
                                                   
                                                        <div className={userAddressDetails.ua_address_type === 'Other' ? "col-lg-6" : "col-lg-12"}>
                                                            <div className="flotting-group mb-10">
                                                                <label className={addressTypeFocused || userAddressDetails.ua_address_type ? "label" : ""}>Address Type</label>
                                                                <select name="ua_address_type" className="form-control required" value={userAddressDetails.ua_address_type}
                                                                    onChange={(e) => onTodoRegChange(e)}
                                                                    onFocus={(e) => setaddressTypeFocused(true)}
                                                                    onBlur={(e) => setaddressTypeFocused(false)}>
                                                                    <option value="">Address Type</option>
                                                                    <option value="Home">Home</option>
                                                                    <option value="Work">Work</option>
                                                                    <option value="Other">Other</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        {userAddressDetails.ua_address_type === 'Other' &&
                                                            <div className='col-lg-6'>
                                                                <div className="flotting-group mb-10">
                                                                    <label className={addressTypeOtherFocused || userAddressDetails.ua_address_type_other ? "label" : ""}>Other</label>
                                                                    <input type="text" name="ua_address_type_other" className="form-control required"
                                                                        value={userAddressDetails.ua_address_type_other}
                                                                        onChange={(e) => onTodoRegChange(e)}
                                                                        onFocus={(e) => setaddressTypeOtherFocused(true)}
                                                                        onBlur={(e) => setaddressTypeOtherFocused(false)}
                                                                        placeholder="Other"
                                                                    />
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="row mb-40">
                                                        <div className="col-lg-12">
                                                            <div className="flotting-group checkboxgroup">
                                                                <input type="checkbox" name='ua_billing_address_check' onChange={(e) => onTodoRegChange(e)} checked={userAddressDetails.ua_billing_address_check}></input>
                                                                <span>My billing address is same as shipping address</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {!userAddressDetails.ua_billing_address_check && (
                                                        <div className="row g-3 mb-20">
                                                            <div className="col-lg-12">
                                                                <div className="cartSectionTitle">
                                                                    <h5 className="mb-0 tx-18">Billing Details</h5>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <div className="flotting-group mb-10">
                                                                    <label className={`${billfirstnameFocused || userAddressDetails.ua_bill_fname ? "label" : ""} `}>First Name</label>
                                                                    <input type="text" className={` required form-control`}
                                                                        name='ua_bill_fname' placeholder="First Name" onChange={(e) => onTodoRegChange(e)}
                                                                        onFocus={(e) => setBillfirstnameFocused(true)}
                                                                        onBlur={(e) => setBillfirstnameFocused(false)}
                                                                    ></input>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <div className="flotting-group mb-10">
                                                                    <label className={billlastnameFocused || userAddressDetails.ua_bill_lname ? "label" : ""}>Last Name</label>
                                                                    <input type="text" className="required form-control" placeholder="Last Name" name='ua_bill_lname'
                                                                        onFocus={(e) => setBilllastnameFocused(true)}
                                                                        onBlur={(e) => setBilllastnameFocused(false)}
                                                                        onChange={(e) => onTodoRegChange(e)}></input>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <div className="flotting-group mb-10">
                                                                    <label className={billmobilenumberFocused || userAddressDetails.ua_bill_mobile ? "label" : ""}>Mobile Number</label>
                                                                    <input type="number" className="required form-control" name="ua_bill_mobile" placeholder="Mobile Number"
                                                                        onChange={(e) => onTodoRegChange(e)}
                                                                        onFocus={(e) => setBillmobilenumberFocused(true)}
                                                                        onBlur={(e) => setBillmobilenumberFocused(false)}></input>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-8">
                                                                <div className="flotting-group mb-10">
                                                                    <label className={billaddressFocused || userAddressDetails.ua_bill_house_no ? "label" : ""}>Address</label>
                                                                    <input type="text" className="required form-control" name="ua_bill_house_no"
                                                                        placeholder="Address (Villa/Apt#, Building Name & Street)"
                                                                        onFocus={(e) => setBilladdressFocused(true)}
                                                                        onBlur={(e) => setBilladdressFocused(false)}
                                                                        onChange={(e) => onTodoRegChange(e)}></input>
                                                                </div>
                                                            </div>
                                                       
                                                            <div className="col-lg-4">
                                                                <div className="flotting-group mb-10">
                                                                    <label className={billcityFocused || userAddressDetails.ua_bill_city_name ? "label" : ""}>City</label>
                                                                    <input type="text" className=" required form-control" name="ua_bill_city_name"
                                                                        onChange={(e) => onTodoRegChange(e)}
                                                                        placeholder="City"
                                                                        onFocus={(e) => setBillcityFocused(true)}
                                                                        onBlur={(e) => setBillcityFocused(false)}
                                                                    ></input>
                                                                </div>
                                                            </div>


                                                            <div className="col-lg-4">
                                                                <div className="flotting-group mb-10">
                                                                    <label className={billcountryFocused || userAddressDetails.ua_bill_country_id ? "label" : ""}>Country</label>

                                                                    <input type="text" className="required form-control" name="ua_bill_country_id" disabled={true}
                                                                        value={'Australia'}
                                                                        onFocus={(e) => setBillcountryFocused(true)}
                                                                        onBlur={(e) => setBillcountryFocused(false)}
                                                                        onChange={(e) => onTodoRegChange(e)}></input>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <div className="flotting-group mb-10">
                                                                    <label className={billstateFocused || userAddressDetails.ua_bill_state_name ? "label" : ""}>State</label>
                                                                    <input type="text" className="required form-control"
                                                                        name="ua_bill_state_name"
                                                                        placeholder="State"
                                                                        onFocus={(e) => setBillstateFocused(true)}
                                                                        onBlur={(e) => setBillstateFocused(false)}
                                                                        onChange={(e) => onTodoRegChange(e)}></input>

                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <div className="flotting-group mb-10">
                                                                    <label className={billpostalFocused || userAddressDetails.ua_bill_pincode ? "label" : ""}>Postal Code</label>
                                                                    <input type="number" className="required form-control"
                                                                        placeholder="Postal Code" name="ua_bill_pincode"
                                                                        onFocus={(e) => setBillpostalFocused(true)}
                                                                        onBlur={(e) => setBillpostalFocused(false)}
                                                                        onChange={(e) => onTodoRegChange(e)}></input>
                                                                </div>
                                                            </div>
                                                          
                                                        </div>
                                                    )}
                                                </>
                                            }
                                            <div className="row g-3 align-items-center">
                                                <div className="col-lg-12">
                                                    <button className="btn btn-primary btn-block btn-large" type="button" onClick={() => { handleContinueProcess() }}>{addAddressSpinnerLoading ? <Loader type="spinner-cub" bgColor={'#fff'} color={'#fff'} size={20} /> : 'Continue'}</button>
                                                </div>
                                                <div className="col-lg-12 tx-center">
                                                    <a href="/cart">Return to cart </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Summary Section */}
                                    <div className="right">
                                        <div className="p-30">
                                            {contextValues.cartSessionData.map((value, index) => {
                                                return (
                                                    <div className="checkoutProduct" key={index}>
                                                        <figure className="checkoutProductMedia">
                                                            <a href={"/product/" + value.product_slug}>
                                                                <img src={value.product_image}></img>
                                                            </a>
                                                        </figure>
                                                        <div className="checkoutProductContent">
                                                            <h2 className="title mb-1">{value.product_name}</h2>
                                                            <div className="cpcvar mb-10">
                                                                {/* <div><span className="tx-gray">Size:</span> </div> */}
                                                                <div><span className="tx-gray">Qty:</span> {value.quantity}</div>
                                                            </div>
                                                            <div className="price">
                                                                <ins className="new-price">{discountCurrency(value.product_selling_price)}</ins>
                                                                {value.product_discount > 0 && (<del className="old-price">{discountCurrency(value.product_price)}</del>)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                            {/* Coupon */}
                                            <div className="cartSectionCoupon mb-20">
                                                <input type="text" placeholder="Enter Coupon Code"
                                                    name="promo_code"
                                                    value={promoCode.promo_code}
                                                    onChange={(e) => onTodoChangePromo(e)}
                                                ></input>
                                                <button type="button" className="applybutton" onClick={(e) => applyCouponTyping()}>{couponSpinnerLoading ? <Loader type="spinner-cub" bgColor={'#fff'} color={'#fff'} size={20} /> : 'Apply'}</button>
                                            </div>
                                            {contextValues.couponSession && contextValues.couponSession.promo_id > 0 &&
                                                <div className="d-flex justify-content-between mb-20">
                                                    <div>
                                                        <h6 className="tx-11 mb-0">{contextValues.couponSession.promo_code} applied successfully</h6>
                                                        <span className="tx-12 tx-green">You save <span className="tx-green">{discountCurrency(contextValues.couponSession.discount_amount)}</span> on this order!</span>
                                                    </div>
                                                    <div className="itemscount" style={{ color: 'red' }} onClick={(e) => removeCoupon()}><i className="ri-delete-bin-5-line ri-lg"></i></div>
                                                </div>

                                            }
                                            <ul className="cartSectionPrice">
                                                <li>
                                                    <span>Sub Total</span>
                                                    <span>{discountCurrency(contextValues.cartSummary.itemTotal)}</span>
                                                </li>
                                                {contextValues.cartSummary && contextValues.cartSummary.discount > 0 && (
                                                    <li>
                                                        <span>Discount on MRP</span>
                                                        <span className="tx-green">-{discountCurrency(contextValues.cartSummary.discount)}</span>
                                                    </li>
                                                )}
                                                {contextValues.couponSession && contextValues.couponSession.discount_amount > 0 && (
                                                    <li>
                                                        <span>Coupon Discount</span>
                                                        <span className="tx-green">-{discountCurrency(contextValues.couponSession.discount_amount)}</span>
                                                    </li>
                                                )}
                                                <li>
                                                    <span>Shipping</span>
                                                    <span>{shippingCharge !== '' ? discountCurrency(shippingCharge) : 'Calculated at next step'}</span>
                                                </li>
                                                <hr></hr>
                                                <li className="bigrow">
                                                    <span>Total Amount</span>
                                                    <span>{discountCurrency(parseFloat(contextValues.cartSummary.itemTotal) + parseFloat(shippingCharge !== '' ? shippingCharge : 0) - parseFloat(contextValues.cartSummary.discount) - parseFloat(contextValues.couponSession && contextValues.couponSession.discount_amount ? contextValues.couponSession.discount_amount : 0))}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : null}
                    </BrowserView>
                    <MobileView>
                        <div className="pb-5 mb-3">
                            <div className="ordersummaryToggle">
                                <div className="ordersummaryToggleHed"
                                    onClick={() => setOpen(!open)}
                                    aria-expanded={open}
                                >
                                    <div>
                                        <h6 className="mb-0 tx-14"><i className="ri-shopping-cart-line"></i> Order Summary</h6>
                                    </div>
                                    <div>{discountCurrency(parseFloat(contextValues.cartSummary.itemTotal) + parseFloat(shippingCharge !== '' ? shippingCharge : 0) - parseFloat(contextValues.cartSummary.discount) - parseFloat(contextValues.couponSession && contextValues.couponSession.discount_amount ? contextValues.couponSession.discount_amount : 0))} <i className="ri-arrow-down-s-line"></i></div>
                                </div>
                                <Collapse in={open}>
                                    <div className="ordersummaryTogglecont">
                                        <div className="p-15">
                                            {contextValues.cartSessionData.map((value, index) => {
                                                return (
                                                    <div className="checkoutProduct" key={index}>
                                                        <figure className="checkoutProductMedia">
                                                            <a href={"/product/" + value.product_slug}>
                                                                <img src={value.product_image}></img>
                                                            </a>
                                                        </figure>
                                                        <div className="checkoutProductContent">
                                                            <h2 className="title mb-1">{value.product_name}</h2>
                                                            <div className="cpcvar mb-10">
                                                                {/* <div><span className="tx-gray">Size:</span> </div> */}
                                                                <div><span className="tx-gray">Qty:</span> {value.quantity}</div>
                                                            </div>
                                                            <div className="price">
                                                                <ins className="new-price">{discountCurrency(value.product_selling_price)}</ins>
                                                                {value.product_discount > 0 && (<del className="old-price">{discountCurrency(value.product_price)}</del>)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}

                                            <ul className="cartSectionPrice">
                                                <li>
                                                    <span>Sub Total</span>
                                                    <span>{discountCurrency(contextValues.cartSummary.itemTotal)}</span>
                                                </li>
                                                {contextValues.cartSummary && contextValues.cartSummary.discount > 0 && (
                                                    <li>
                                                        <span>Discount on MRP</span>
                                                        <span className="tx-green">-{discountCurrency(contextValues.cartSummary.discount)}</span>
                                                    </li>
                                                )}
                                                {contextValues.couponSession && contextValues.couponSession.discount_amount > 0 && (
                                                    <li>
                                                        <span>Coupon Discount</span>
                                                        <span className="tx-green">-{discountCurrency(contextValues.couponSession.discount_amount)}</span>
                                                    </li>
                                                )}
                                                <li>
                                                    <span>Shipping</span>
                                                    <span>{shippingCharge !== '' ? discountCurrency(shippingCharge) : 'Calculated at next step'}</span>
                                                </li>
                                                <hr></hr>
                                                <li className="bigrow">
                                                    <span>Total Amount</span>
                                                    <span>{discountCurrency(parseFloat(contextValues.cartSummary.itemTotal) + parseFloat(shippingCharge !== '' ? shippingCharge : 0) - parseFloat(contextValues.cartSummary.discount) - parseFloat(contextValues.couponSession && contextValues.couponSession.discount_amount ? contextValues.couponSession.discount_amount : 0))}</span>
                                                </li>
                                            </ul>
                                        </div>

                                    </div>
                                </Collapse>
                            </div>
                            <div className="cardPanel">
                                <div className="cardPanelBody">
                                    {/* Login Section */}
                                    {contextValues.userToken ?
                                        <div>
                                            <div className="cartSectionTitle mb-10">
                                                <h5 className="mb-0 tx-14">Account</h5>
                                                <a href="javascript:void(0);" className="tx-primary" onClick={(e) => logoutUser()}>Log Out</a>
                                            </div>
                                            <div>
                                                <h6 className="mb-0 tx-14">{rowUserData.user_fname}</h6>
                                                <p className="mb-0 tx-13">{rowUserData.user_email}</p>
                                            </div>
                                        </div>
                                        :
                                        <div>
                                            <div className="cartSectionTitle mb-20">
                                                <h5 className="mb-0 tx-14">Customer information</h5>
                                                <div>
                                                    Have an account? <a href="javascript:void(0);" className="tx-primary" onClick={(e) => showLogin()}>Login</a>
                                                </div>
                                            </div>
                                            {hideShowLogin ?
                                                <>
                                                    <div className="flotting-group mb-15">
                                                        <label className={emailFocused || userLoginDetails.user_email ? "label" : ""}>Email Address</label>
                                                        <input
                                                            type="text"
                                                            className="form-control loginRequired"
                                                            placeholder="Email Address"
                                                            name="user_email"
                                                            value={userLoginDetails.user_email}
                                                            onChange={(e) => onTodoChange(e)}
                                                            onFocus={(e) => setEmailFocused(true)}
                                                            onBlur={(e) => setEmailFocused(false)}></input>
                                                    </div>
                                                    <div className="flotting-group mb-15">
                                                        <label className={passwordFocused || userLoginDetails.user_password ? "label" : ""}>Password</label>
                                                        <input
                                                            type="password"
                                                            className="form-control loginRequired"
                                                            placeholder="Password"
                                                            name="user_password"
                                                            value={userLoginDetails.user_password}
                                                            onChange={(e) => onTodoChange(e)}
                                                            onFocus={(e) => setPasswordFocused(true)}
                                                            onBlur={(e) => setPasswordFocused(false)}></input>
                                                    </div>
                                                    <button className="btn btn-primary btn-block" style={{ padding: '12px' }} type="button" onClick={(e) => userLoginProcess()} >{loginSpinnerLoading ? <Loader type="spinner-cub" bgColor={'#fff'} color={'#fff'} size={12} /> : <> Login</>}</button>

                                                </>
                                                :
                                                <>

                                                    <div className="flotting-group">
                                                        <label className={emailFocused || userLoginDetails.user_email ? "label" : ""}>Email Address</label>
                                                        <input type="email" className="guestloginRequired form-control" name='user_email' placeholder="Email Address" value={userLoginDetails.user_email} onChange={(e) => onTodoChange(e)} onFocus={(e) => setEmailFocused(true)}
                                                            onBlur={(e) => setEmailFocused(false)}></input>
                                                    </div>

                                                </>
                                            }
                                        </div>
                                    }
                                </div>

                            </div>
                            <div className="cardPanel">
                                <div className="cardPanelBody">
                                    {contextValues.userToken ?
                                        <>
                                            {/* Address Section */}
                                            {contextValues.userAddressList.length > 0 ?
                                                <>

                                                    <div className="cartSectionTitle mb-20">
                                                        <h5 className="mb-0 tx-14">Shipping Details</h5>
                                                    </div>

                                                    <div >
                                                        <div>
                                                            {contextValues.userAddressList.length > 0 && contextValues.userAddressList.map((value, index) => (
                                                                <div>
                                                                    <div className={`cartAddressbox ${value.ua_default_address == 1 ? 'active' : ''}`} key={index}
                                                                        onClick={(e) => selectAddress(value)}>
                                                                        <input type="radio" checked={value.ua_default_address == 1 ? true : false}></input>
                                                                        <h6 className="mb-0 tx-14">{value.ua_fname} {value.ua_lname}</h6>
                                                                        <p className="mb-1 tx-12">{value.ua_house_no}, {value.ua_city_name}, {value.ua_state_name}, {value.ua_pincode}</p>
                                                                        <p className="tx-gray mb-0 tx-12">Mobile No: {value.ua_mobile}</p>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                        <button type="button" className="btn btn-primary-outline btn-small d-flex align-items-center  mt-20" onClick={(e) => toggleCheckoutAddressModal()}><i className="ri-add-circle-line ri-lg mr-5"></i>Add New Address</button>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <div>
                                                        <div className="cartSectionTitle mb-20">
                                                            <h5 className="mb-0 tx-14">Shipping Details</h5>
                                                        </div>
                                                        <div className="flotting-group mb-15">
                                                            <label className={`${firstnameFocused || userAddressDetails.ua_fname ? "label" : ""} `}>First Name</label>
                                                            <input type="text" className={` required form-control`}
                                                                name='ua_fname' placeholder="First Name" onChange={(e) => onTodoRegChange(e)}
                                                                onFocus={(e) => setfirstnameFocused(true)}
                                                                onBlur={(e) => setfirstnameFocused(false)}
                                                            ></input>
                                                        </div>
                                                        <div className="flotting-group mb-15">
                                                            <label className={lastnameFocused || userAddressDetails.ua_lname ? "label" : ""}>Last Name</label>
                                                            <input type="text" className="required form-control" placeholder="Last Name" name='ua_lname'
                                                                onFocus={(e) => setlastnameFocused(true)}
                                                                onBlur={(e) => setlastnameFocused(false)}
                                                                onChange={(e) => onTodoRegChange(e)}></input>
                                                        </div>
                                                        <div className="flotting-group mb-15">
                                                            <label className={mobilenumberFocused || userAddressDetails.ua_mobile ? "label" : ""}>Mobile Number</label>
                                                            <input type="number" className="required form-control" name="ua_mobile" placeholder="Mobile Number"
                                                                onChange={(e) => onTodoRegChange(e)}
                                                                onFocus={(e) => setmobilenumberFocused(true)}
                                                                onBlur={(e) => setmobilenumberFocused(false)}></input>
                                                        </div>
                                                        <div className="flotting-group mb-15">
                                                            <label className={addressFocused || userAddressDetails.ua_house_no ? "label" : ""}>Address</label>
                                                            <input type="text" className="required form-control" name="ua_house_no"
                                                                placeholder="Address (Villa/Apt#, Building Name & Street)"
                                                                onFocus={(e) => setaddressFocused(true)}
                                                                onBlur={(e) => setaddressFocused(false)}
                                                                onChange={(e) => onTodoRegChange(e)}></input>
                                                        </div>
                                                    
                                                        <div className="flotting-group mb-15">
                                                            <label className={cityFocused || userAddressDetails.ua_city_name ? "label" : ""}>City</label>
                                                            <input type="text" className=" required form-control" name="ua_city_name"
                                                                onChange={(e) => onTodoRegChange(e)}
                                                                placeholder="City"
                                                                onFocus={(e) => setcityFocused(true)}
                                                                onBlur={(e) => setcityFocused(false)}
                                                            ></input>
                                                        </div>


                                                        <div className="flotting-group mb-15">
                                                            <label className={countryFocused || userAddressDetails.ua_country_id ? "label" : ""}>Country</label>
                                                            <input type="text" className="required form-control" name="ua_country_id" disabled={true}
                                                                value={'Australia'}
                                                                onFocus={(e) => setcountryFocused(true)}
                                                                onBlur={(e) => setcountryFocused(false)}
                                                                onChange={(e) => onTodoRegChange(e)}></input>
                                                        </div>
                                                        <div className="flotting-group mb-15">
                                                            <label className={stateFocused || userAddressDetails.ua_state_name ? "label" : ""}>State</label>
                                                            <input type="text" className="required form-control"
                                                                name="ua_state_name"
                                                                placeholder="State"
                                                                onFocus={(e) => setstateFocused(true)}
                                                                onBlur={(e) => setstateFocused(false)}
                                                                onChange={(e) => onTodoRegChange(e)}></input>
                                                        </div>
                                                        <div className="flotting-group mb-15">
                                                            <label className={postalFocused || userAddressDetails.ua_pincode ? "label" : ""}>Postal Code</label>
                                                            <input type="number" className="required form-control"
                                                                placeholder="Postal Code" name="ua_pincode"
                                                                onFocus={(e) => setpostalFocused(true)}
                                                                onBlur={(e) => setpostalFocused(false)}
                                                                onChange={(e) => onTodoRegChange(e)}></input>
                                                        </div>
                                                       
                                                        <div className="flotting-group mb-10">
                                                            <label className={addressTypeFocused || userAddressDetails.ua_address_type ? "label" : ""}>Address Type</label>
                                                            <select name="ua_address_type" className="form-control required" value={userAddressDetails.ua_address_type}
                                                                onChange={(e) => onTodoRegChange(e)}
                                                                onFocus={(e) => setaddressTypeFocused(true)}
                                                                onBlur={(e) => setaddressTypeFocused(false)}>
                                                                <option value="">Address Type</option>
                                                                <option value="Home">Home</option>
                                                                <option value="Work">Work</option>
                                                                <option value="Other">Other</option>
                                                            </select>
                                                        </div>
                                                        {userAddressDetails.ua_address_type === 'Other' &&
                                                            <div className="flotting-group mb-10">
                                                                <label className={addressTypeOtherFocused || userAddressDetails.ua_address_type_other ? "label" : ""}>Other</label>
                                                                <input type="text" name="ua_address_type_other" className="form-control required"
                                                                    value={userAddressDetails.ua_address_type_other}
                                                                    onChange={(e) => onTodoRegChange(e)}
                                                                    onFocus={(e) => setaddressTypeOtherFocused(true)}
                                                                    onBlur={(e) => setaddressTypeOtherFocused(false)}
                                                                    placeholder="Other"
                                                                />
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="flotting-group checkboxgroup mb-20">
                                                        <input type="checkbox" name='ua_billing_address_check' onChange={(e) => onTodoRegChange(e)} checked={userAddressDetails.ua_billing_address_check}></input>
                                                        <span>My billing address is same as shipping address</span>
                                                    </div>
                                                    {!userAddressDetails.ua_billing_address_check && (
                                                        <div>
                                                            <div className="cartSectionTitle mb-20">
                                                                <h5 className="mb-0 tx-14">Billing Details</h5>
                                                            </div>
                                                            <div className="flotting-group mb-15">
                                                                <label className={`${billfirstnameFocused || userAddressDetails.ua_bill_fname ? "label" : ""} `}>First Name</label>
                                                                <input type="text" className={` required form-control`}
                                                                    name='ua_bill_fname' placeholder="First Name" onChange={(e) => onTodoRegChange(e)}
                                                                    onFocus={(e) => setBillfirstnameFocused(true)}
                                                                    onBlur={(e) => setBillfirstnameFocused(false)}
                                                                ></input>
                                                            </div>
                                                            <div className="flotting-group mb-15">
                                                                <label className={billlastnameFocused || userAddressDetails.ua_bill_lname ? "label" : ""}>Last Name</label>
                                                                <input type="text" className="required form-control" placeholder="Last Name" name='ua_bill_lname'
                                                                    onFocus={(e) => setBilllastnameFocused(true)}
                                                                    onBlur={(e) => setBilllastnameFocused(false)}
                                                                    onChange={(e) => onTodoRegChange(e)}></input>
                                                            </div>
                                                            <div className="flotting-group mb-15">
                                                                <label className={billmobilenumberFocused || userAddressDetails.ua_bill_mobile ? "label" : ""}>Mobile Number</label>
                                                                <input type="number" className="required form-control" name="ua_bill_mobile" placeholder="Mobile Number"
                                                                    onChange={(e) => onTodoRegChange(e)}
                                                                    onFocus={(e) => setBillmobilenumberFocused(true)}
                                                                    onBlur={(e) => setBillmobilenumberFocused(false)}></input>
                                                            </div>
                                                            <div className="flotting-group mb-15">
                                                                <label className={billaddressFocused || userAddressDetails.ua_bill_house_no ? "label" : ""}>Address</label>
                                                                <input type="text" className="required form-control" name="ua_bill_house_no"
                                                                    placeholder="Address (Villa/Apt#, Building Name & Street)"
                                                                    onFocus={(e) => setBilladdressFocused(true)}
                                                                    onBlur={(e) => setBilladdressFocused(false)}
                                                                    onChange={(e) => onTodoRegChange(e)}></input>
                                                            </div>
                                                            <div className="flotting-group mb-15">
                                                                <label className={billcityFocused || userAddressDetails.ua_bill_city_name ? "label" : ""}>City</label>
                                                                <input type="text" className=" required form-control" name="ua_bill_city_name"
                                                                    onChange={(e) => onTodoRegChange(e)}
                                                                    placeholder="City"
                                                                    onFocus={(e) => setBillcityFocused(true)}
                                                                    onBlur={(e) => setBillcityFocused(false)}
                                                                ></input>
                                                            </div>


                                                            <div className="flotting-group mb-15">
                                                                <label className={billcountryFocused || userAddressDetails.ua_bill_country_id ? "label" : ""}>Country</label>

                                                                <input type="text" className="required form-control" name="ua_bill_country_id" disabled={true}
                                                                    value={'Australia'}
                                                                    onFocus={(e) => setBillcountryFocused(true)}
                                                                    onBlur={(e) => setBillcountryFocused(false)}
                                                                    onChange={(e) => onTodoRegChange(e)}></input>
                                                            </div>
                                                            <div className="flotting-group mb-15">
                                                                <label className={billstateFocused || userAddressDetails.ua_bill_state_name ? "label" : ""}>State</label>
                                                                <input type="text" className="required form-control"
                                                                    name="ua_bill_state_name"
                                                                    placeholder="State"
                                                                    onFocus={(e) => setBillstateFocused(true)}
                                                                    onBlur={(e) => setBillstateFocused(false)}
                                                                    onChange={(e) => onTodoRegChange(e)}></input>

                                                            </div>
                                                            <div className="flotting-group mb-15">
                                                                <label className={billpostalFocused || userAddressDetails.ua_bill_pincode ? "label" : ""}>Postal Code</label>
                                                                <input type="number" className="required form-control"
                                                                    placeholder="Postal Code" name="ua_bill_pincode"
                                                                    onFocus={(e) => setBillpostalFocused(true)}
                                                                    onBlur={(e) => setBillpostalFocused(false)}
                                                                    onChange={(e) => onTodoRegChange(e)}></input>
                                                            </div>
                                                         
                                                        </div>
                                                    )}
                                                </>
                                            }
                                        </>
                                        :
                                        <>
                                            <div>
                                                <div className="cartSectionTitle mb-20">
                                                    <h5 className="mb-0 tx-14">Shipping Details</h5>
                                                </div>
                                                <div className="flotting-group mb-15">
                                                    <label className={`${firstnameFocused || userAddressDetails.ua_fname ? "label" : ""} `}>First Name</label>
                                                    <input type="text" className={` required form-control`}
                                                        name='ua_fname' placeholder="First Name" onChange={(e) => onTodoRegChange(e)}
                                                        onFocus={(e) => setfirstnameFocused(true)}
                                                        onBlur={(e) => setfirstnameFocused(false)}
                                                    ></input>
                                                </div>
                                                <div className="flotting-group mb-15">
                                                    <label className={lastnameFocused || userAddressDetails.ua_lname ? "label" : ""}>Last Name</label>
                                                    <input type="text" className="required form-control" placeholder="Last Name" name='ua_lname'
                                                        onFocus={(e) => setlastnameFocused(true)}
                                                        onBlur={(e) => setlastnameFocused(false)}
                                                        onChange={(e) => onTodoRegChange(e)}></input>
                                                </div>
                                                <div className="flotting-group mb-15">
                                                    <label className={mobilenumberFocused || userAddressDetails.ua_mobile ? "label" : ""}>Mobile Number</label>
                                                    <input type="number" className="required form-control" name="ua_mobile" placeholder="Mobile Number"
                                                        onChange={(e) => onTodoRegChange(e)}
                                                        onFocus={(e) => setmobilenumberFocused(true)}
                                                        onBlur={(e) => setmobilenumberFocused(false)}></input>
                                                </div>
                                                <div className="flotting-group mb-15">
                                                    <label className={addressFocused || userAddressDetails.ua_house_no ? "label" : ""}>Address</label>
                                                    <input type="text" className="required form-control" name="ua_house_no"
                                                        placeholder="Address (Villa/Apt#, Building Name & Street)"
                                                        onFocus={(e) => setaddressFocused(true)}
                                                        onBlur={(e) => setaddressFocused(false)}
                                                        onChange={(e) => onTodoRegChange(e)}></input>
                                                </div>
                                              
                                                <div className="flotting-group mb-10">
                                                    <label className={cityFocused || userAddressDetails.ua_city_name ? "label" : ""}>City</label>
                                                    <input type="text" className=" required form-control" name="ua_city_name"
                                                        onChange={(e) => onTodoRegChange(e)}
                                                        placeholder="City"
                                                        onFocus={(e) => setcityFocused(true)}
                                                        onBlur={(e) => setcityFocused(false)}
                                                    ></input>
                                                </div>
                                                <div className="flotting-group mb-15">
                                                    <label className={countryFocused || userAddressDetails.ua_country_id ? "label" : ""}>Country</label>
                                                    <input type="text" className="required form-control" name="ua_country_id" disabled={true}
                                                        value={'Australia'}
                                                        onFocus={(e) => setcountryFocused(true)}
                                                        onBlur={(e) => setcountryFocused(false)}
                                                        onChange={(e) => onTodoRegChange(e)}></input>
                                                </div>
                                                <div className="flotting-group mb-15">
                                                    <label className={stateFocused || userAddressDetails.ua_state_name ? "label" : ""}>State</label>
                                                    <input type="text" className="required form-control"
                                                        name="ua_state_name"
                                                        placeholder="State"
                                                        onFocus={(e) => setstateFocused(true)}
                                                        onBlur={(e) => setstateFocused(false)}
                                                        onChange={(e) => onTodoRegChange(e)}></input>
                                                </div>
                                                <div className="flotting-group mb-15">
                                                    <label className={postalFocused || userAddressDetails.ua_pincode ? "label" : ""}>Postal Code</label>
                                                    <input type="number" className="required form-control"
                                                        placeholder="Postal Code" name="ua_pincode"
                                                        onFocus={(e) => setpostalFocused(true)}
                                                        onBlur={(e) => setpostalFocused(false)}
                                                        onChange={(e) => onTodoRegChange(e)}></input>
                                                </div>

                                              
                                                <div className="flotting-group mb-10">
                                                    <label className={addressTypeFocused || userAddressDetails.ua_address_type ? "label" : ""}>Address Type</label>
                                                    <select name="ua_address_type" className="form-control required" value={userAddressDetails.ua_address_type}
                                                        onChange={(e) => onTodoRegChange(e)}
                                                        onFocus={(e) => setaddressTypeFocused(true)}
                                                        onBlur={(e) => setaddressTypeFocused(false)}>
                                                        <option value="">Address Type</option>
                                                        <option value="Home">Home</option>
                                                        <option value="Work">Work</option>
                                                        <option value="Other">Other</option>
                                                    </select>
                                                </div>
                                                {userAddressDetails.ua_address_type === 'Other' &&
                                                    <div className="flotting-group mb-10">
                                                        <label className={addressTypeOtherFocused || userAddressDetails.ua_address_type_other ? "label" : ""}>Other</label>
                                                        <input type="text" name="ua_address_type_other" className="form-control required"
                                                            value={userAddressDetails.ua_address_type_other}
                                                            onChange={(e) => onTodoRegChange(e)}
                                                            onFocus={(e) => setaddressTypeOtherFocused(true)}
                                                            onBlur={(e) => setaddressTypeOtherFocused(false)}
                                                            placeholder="Other"
                                                        />
                                                    </div>
                                                }
                                            </div>
                                            <div className="flotting-group checkboxgroup mb-20">
                                                <input type="checkbox" name='ua_billing_address_check' onChange={(e) => onTodoRegChange(e)} checked={userAddressDetails.ua_billing_address_check}></input>
                                                <span>My billing address is same as shipping address</span>
                                            </div>
                                            {!userAddressDetails.ua_billing_address_check && (
                                                <div>
                                                    <div className="cartSectionTitle mb-20">
                                                        <h5 className="mb-0 tx-14">Billing Details</h5>
                                                    </div>
                                                    <div className="flotting-group mb-15">
                                                        <label className={`${billfirstnameFocused || userAddressDetails.ua_bill_fname ? "label" : ""} `}>First Name</label>
                                                        <input type="text" className={` required form-control`}
                                                            name='ua_bill_fname' placeholder="First Name" onChange={(e) => onTodoRegChange(e)}
                                                            onFocus={(e) => setBillfirstnameFocused(true)}
                                                            onBlur={(e) => setBillfirstnameFocused(false)}
                                                        ></input>
                                                    </div>
                                                    <div className="flotting-group mb-15">
                                                        <label className={billlastnameFocused || userAddressDetails.ua_bill_lname ? "label" : ""}>Last Name</label>
                                                        <input type="text" className="required form-control" placeholder="Last Name" name='ua_bill_lname'
                                                            onFocus={(e) => setBilllastnameFocused(true)}
                                                            onBlur={(e) => setBilllastnameFocused(false)}
                                                            onChange={(e) => onTodoRegChange(e)}></input>
                                                    </div>
                                                    <div className="flotting-group mb-15">
                                                        <label className={billmobilenumberFocused || userAddressDetails.ua_bill_mobile ? "label" : ""}>Mobile Number</label>
                                                        <input type="number" className="required form-control" name="ua_bill_mobile" placeholder="Mobile Number"
                                                            onChange={(e) => onTodoRegChange(e)}
                                                            onFocus={(e) => setBillmobilenumberFocused(true)}
                                                            onBlur={(e) => setBillmobilenumberFocused(false)}></input>
                                                    </div>
                                                    <div className="col-lg-8">
                                                        <div className="flotting-group mb-15">
                                                            <label className={billaddressFocused || userAddressDetails.ua_bill_house_no ? "label" : ""}>Address</label>
                                                            <input type="text" className="required form-control" name="ua_bill_house_no"
                                                                placeholder="Address (Villa/Apt#, Building Name & Street)"
                                                                onFocus={(e) => setBilladdressFocused(true)}
                                                                onBlur={(e) => setBilladdressFocused(false)}
                                                                onChange={(e) => onTodoRegChange(e)}></input>
                                                        </div>
                                                    </div>
                                                 
                                                    <div className="flotting-group mb-15">
                                                        <label className={billcityFocused || userAddressDetails.ua_bill_city_name ? "label" : ""}>City</label>
                                                        <input type="text" className=" required form-control" name="ua_bill_city_name"
                                                            onChange={(e) => onTodoRegChange(e)}
                                                            placeholder="City"
                                                            onFocus={(e) => setBillcityFocused(true)}
                                                            onBlur={(e) => setBillcityFocused(false)}
                                                        ></input>
                                                    </div>
                                                    <div className="flotting-group mb-15">
                                                        <label className={billcountryFocused || userAddressDetails.ua_bill_country_id ? "label" : ""}>Country</label>

                                                        <input type="text" className="required form-control" name="ua_bill_country_id" disabled={true}
                                                            value={'Australia'}
                                                            onFocus={(e) => setBillcountryFocused(true)}
                                                            onBlur={(e) => setBillcountryFocused(false)}
                                                            onChange={(e) => onTodoRegChange(e)}></input>
                                                    </div>
                                                    <div className="flotting-group mb-15">
                                                        <label className={billstateFocused || userAddressDetails.ua_bill_state_name ? "label" : ""}>State</label>
                                                        <input type="text" className="required form-control"
                                                            name="ua_bill_state_name"
                                                            placeholder="State"
                                                            onFocus={(e) => setBillstateFocused(true)}
                                                            onBlur={(e) => setBillstateFocused(false)}
                                                            onChange={(e) => onTodoRegChange(e)}></input>

                                                    </div>

                                                    <div className="flotting-group mb-15">
                                                        <label className={billpostalFocused || userAddressDetails.ua_bill_pincode ? "label" : ""}>Postal Code</label>
                                                        <input type="number" className="required form-control"
                                                            placeholder="Postal Code" name="ua_bill_pincode"
                                                            onFocus={(e) => setBillpostalFocused(true)}
                                                            onBlur={(e) => setBillpostalFocused(false)}
                                                            onChange={(e) => onTodoRegChange(e)}></input>
                                                    </div>
                                                
                                                </div>
                                            )}
                                        </>
                                    }
                                </div>
                            </div>
                            <div className="cardPanel">
                                <div className="cardPanelBody">
                                    {/* Coupon */}
                                    <div className="cartSectionCoupon mb-20">
                                        <input type="text" placeholder="Enter Coupon Code"
                                            name="promo_code"
                                            value={promoCode.promo_code}
                                            onChange={(e) => onTodoChangePromo(e)}
                                        ></input>
                                        <button type="button" className="applybutton" onClick={(e) => applyCouponTyping()}>{couponSpinnerLoading ? <Loader type="spinner-cub" bgColor={'#fff'} color={'#fff'} size={20} /> : 'Apply'}</button>
                                    </div>
                                    {contextValues.couponSession && contextValues.couponSession.promo_id > 0 &&
                                        <div className="d-flex justify-content-between mb-20">
                                            <div>
                                                <h6 className="tx-11 mb-0">{contextValues.couponSession.promo_code} applied successfully</h6>
                                                <span className="tx-12 tx-green">You save <span className="tx-green">{contextValues.couponSession.discount_amount}</span> on this order!</span>
                                            </div>
                                            <div className="itemscount" style={{ color: 'red' }} onClick={(e) => removeCoupon()}><i className="ri-delete-bin-5-line ri-lg"></i></div>
                                        </div>

                                    }
                                </div>
                            </div>
                        </div>
                        <div className="footer-checkout">
                            <button className="btn btn-primary btn-block btn-large" type="button" onClick={() => { handleContinueProcess() }}>{addAddressSpinnerLoading ? <Loader type="spinner-cub" bgColor={'#fff'} color={'#fff'} size={20} /> : 'Continue'}</button>
                        </div>
                    </MobileView>
                </>}
            {contextValues.toggleCheckoutAddressModal && (<CheckoutAddressModal />)}
        </>
    );
}
export default CartAddress;
