import React, { useState } from "react";
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { toast } from "react-toastify";
import Loader from "react-js-loader";
const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const [continuePaySpinnerLoading, setContinuePaySpinnerLoading] = useState(false);
    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }
        setContinuePaySpinnerLoading(true)
        const result = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: "https://admyracollections.au/csadmin/stripe-complete-payment", //https://admyracollections.studykee.com/csadmin/stripe-complete-payment
            },
        });

        if (result.error) { 
            toast.error(result.error.message);
            setContinuePaySpinnerLoading(false)
        } else { 
            setContinuePaySpinnerLoading(false)
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        }
    };
    return (
        <form onSubmit={handleSubmit}>
            <PaymentElement />
            <button className="btn btn-primary btn-block btn-large btn-checkoo mt-20" disabled={!stripe}>{continuePaySpinnerLoading ? <Loader type="spinner-cub" bgColor={'#fff'} color={'#fff'} size={20} /> : 'Pay'}</button>
        </form>
    );
};

export default CheckoutForm;