import React, { useEffect, useState, useRef, useContext } from "react";
import DataContext from "../../Components/Elements/eventContext";
import Modal from "react-bootstrap/Modal";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from "./checkout_form";

const stripePromise = loadStripe('pk_live_51POy4PIu2jePIzmTkEYks3FSzKMLEr6eb7LC4gWsai8DOF61BnVADayrzdEWN37cjSQmlZp0gMKW4oDqwikuLIrd00LrPmNLWu');
//const stripePromise = loadStripe('pk_test_51POy4PIu2jePIzmToHYzHF0oqkM4SfaKeomMgwe5atzBsClEcJQYcnBOykecAOeGJBO3zui38DwYsQXGPX1NnXNq00vsbORf6J')
function StripePaymentModal() {
    const didMountRef = useRef(true);
    const contextValues = useContext(DataContext);
    const appearance = {
        theme: 'stripe',
    };

    const options = {
        clientSecret: contextValues.stripeClientSecretKey,
        appearance: appearance,
        locale: 'en', 
    };
    useEffect(() => {
        if (didMountRef.current) {
        }
        didMountRef.current = false;
    }, []);

    const toggleStripePaymentModal = () => {
        contextValues.setToggleStripePaymentModal(!contextValues.toggleStripePaymentModal)
    }

    return (
        <>
            <Modal show={contextValues.toggleStripePaymentModal} onHide={(e) => toggleStripePaymentModal()} className="addressModal">
                <button type="button" className="pop-close" onClick={(e) => toggleStripePaymentModal()}></button>
                <div className="p-15 mt-20">
                    <Elements stripe={stripePromise} options={options}>
                        <CheckoutForm />
                    </Elements>
                </div>
            </Modal>

        </>
    );
}
export default StripePaymentModal;
