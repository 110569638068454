
import React, { useEffect, useState, useRef, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import CartModal from '../Modals/cart_modal';
import { useLocation, useParams } from "react-router-dom";
import DataContext from "./eventContext";
import LoginModal from "../Modals/login_modal";
import QuickViewModal from "../Modals/quick_view_modal";
import { useNavigate } from 'react-router-dom';
import { ApiService } from "../Services/apiservices";
import sessionCartData from "./cart_session_data";

function MobileHeader({ PageName }) {
  const didMountRef = useRef(true);
  const dataArray = sessionCartData();
  const contextValues = useContext(DataContext);
  const [showHeaderStatus, SetShowHeaderStatus] = useState(false)
  const location = useLocation();
  const { slug } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (didMountRef.current) {
      getSettingsData();
      contextValues.setAddressSession(dataArray[0])
      contextValues.setCouponSession(dataArray[2])
      contextValues.setCartSummary(dataArray[3])
      if (localStorage.getItem("USER_TOKEN")) {
        cartSessionData();
      } else {
        contextValues.setCartCount(dataArray[1].length)
        contextValues.setCartSessionData(dataArray[1])
      }
      const currentPath = location.pathname;
      const isCategoryPage = currentPath.includes("/category");
      const isProductDetailsPage = currentPath.includes("/product/" + slug);
      const isTagPage = currentPath.includes("/collection/tag/" + slug);
      if (isCategoryPage || isProductDetailsPage || isTagPage) {
        SetShowHeaderStatus(true)
      } else {
        SetShowHeaderStatus(false)
      }
    }
    didMountRef.current = false;
  }, []);
  const [showCart, setShowCart] = useState(false);
    const handleShowCart = () => setShowCart(true);
    const handleChildCartData = (status) => {
      setShowCart(status)
    };
    const handleClose = () => {
      setShowCart(false);
    };

  const cartSessionData = () => {
    const dataString = {
      product_id: '',
    };
    ApiService.postData("cartSessionData", dataString).then((res) => {
      if (res.data.status === "success") {
        contextValues.setCartSessionData(res.data.resCartData)
        contextValues.setCartCount(res.data.resCartData.length)
        contextValues.setCartSummary(res.data.cartSummary)
      }
    });
  }

  const getSettingsData = () => {
    ApiService.fetchData("settings").then((res) => {
      if (res.status === "success") {
        contextValues.setSettingData(res.sitesettings);
        contextValues.setSettingImageBaseUrl(res.setting_image_path)
      }
    });
  };

  const goBack = () => {
    navigate(-1); // Navigate back to the previous page
  };
  const quickviewmodal = () => {
    contextValues.setToggleQuickViewModal(!contextValues.toggleQuickViewModal)
  }
  const cartModal = () => {
    contextValues.setToggleCartModal(!contextValues.toggleCartModal)
  }
  return (
    <>
      <header className="mheader d-flex">
        <div className="mheader-left">
          <div className="mheader-title">
            <a href="javascript:void(0)" onClick={(e) => goBack()}>
              <i className="d-icon-arrow-left"></i>
            </a>
            {PageName}
          </div>
        </div>
        {showHeaderStatus ?
          <div className="mheader-right">
            <a className="search-link" href="/search" title="search">
              <i className="d-icon-search"></i>
            </a>
            <a
              className="cart-toggle"
              href="javascript:void(0);"
              title="cart"
              onClick={handleShowCart}
            >
              <i className="d-icon-bag"></i>
              <span className="cart-count">{contextValues.cartCount}</span>
            </a>
          </div> : null}
      </header>
      <LoginModal />
      <Modal show={contextValues.toggleQuickViewModal} onHide={(e) => quickviewmodal()} className="quickViewModal">
        {contextValues.toggleQuickViewModal && (<QuickViewModal />)}
      </Modal>
      <Modal show={showCart} onHide={handleClose} className="right cart-modal">
        {showCart && <CartModal showcartmodal={showCart} onChildCartData={handleChildCartData} />}
      </Modal>

    </>
  );
}
export default MobileHeader;

