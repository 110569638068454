import React, { useEffect, useState, useRef } from "react";
import { ApiService } from "../../Components/Services/apiservices";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { BrowserView, MobileView } from "react-device-detect";
import Modal from "react-bootstrap/Modal";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import multiCurrency from "../../Components/Elements/multi_currrency";
function Productlistsidebar({
  categoryName,
  sorting = null,
  slug,
  type,
  onFilteredData,
  categoryImage,
  setscroll = true,
  showmodal,
  HandleCloseModal,
  sendDataToParent 
  }) {
  const didMountRef = useRef(true);
  const [aryCategoryList, setCategoryList] = useState([]);
  const [resAttributesList, setAttributesList] = useState([]);
  const [resCollectionList , setResCollectionList] = useState([])
  const [resProductTypeList , setResProductTypeList] = useState([])
  const [loading, setLoading] = useState(false);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState({});
  const [showFilter, setShowFilter] = useState(showmodal);
  const [selectedTab, setSelectedTab] = useState("tab-collection");
  const [priceRange, setPriceRange] = useState([0, 100000]);

  useEffect(() => {
    if (sorting) {
      // getfilterproducts(priceRange);
      // handleCheckboxChange('sort',sorting);
    }
  }, [sorting]);
  useEffect(() => {
    setShowFilter(showmodal);
  }, [showmodal]);
 useEffect(() => {
    if (didMountRef.current) {
      if(localStorage.getItem('FILTER_SESSION') && localStorage.getItem('FILTER_SESSION')!=null){
      setSelectedCheckboxes(JSON.parse(localStorage.getItem('FILTER_SESSION')))
      }

      getProductListSidebar();
    

    }
    didMountRef.current = false;
  }, []);
const rangeHandleChange=(range)=>{
 localStorage.setItem('RANGE_SESSION', JSON.stringify(range));
  const rangeSession = JSON.parse(localStorage.getItem('RANGE_SESSION'))
  window.location.reload()
  setPriceRange(rangeSession[1])
      
}
const rangeHandleChangeMobile=(range)=>{
 localStorage.setItem('RANGE_SESSION', JSON.stringify(range));
  const rangeSession = JSON.parse(localStorage.getItem('RANGE_SESSION'))
  setPriceRange(rangeSession)
      
}


  const getProductListSidebar = () => {
    ApiService.fetchData("get-product-list-sidebar").then((res) => {
      if (res.status === "success") {
        setAttributesList(res.resAttributesList);
        setCategoryList(res.aryCategoryList);
        setResCollectionList(res.resCollectionList)
        setResProductTypeList(res.resProductTypeList)
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

 
  const handleCheckboxChange = (checkboxKey, checkboxValue) => {


    setSelectedCheckboxes((prevSelectedCheckboxes) => {
      // Multiple selection for checkboxes other than "category"
      const updatedCheckboxes = { ...prevSelectedCheckboxes };
      if (updatedCheckboxes[checkboxKey]) {
        // Check if the checkboxValue already exists
        const existingValues = updatedCheckboxes[checkboxKey];
        if (existingValues.includes(checkboxValue)) {
          // Remove the checkbox value if already selected
          updatedCheckboxes[checkboxKey] = existingValues.filter(
            (id) => id !== checkboxValue
          );
        } else {
          // Add the checkbox value if not selected
          updatedCheckboxes[checkboxKey] = [...existingValues, checkboxValue];
        }
      } else {
        // Checkbox key does not exist, initialize with an array containing the checkbox value
        updatedCheckboxes[checkboxKey] = [checkboxValue];
      }
      localStorage.setItem('FILTER_SESSION', JSON.stringify(updatedCheckboxes));
      window.location.reload()

      return updatedCheckboxes;
    });
  
    };
 

  const handleClose = () => {
    window.location.reload()
  };
  const handleTabChange = (id) => {
    setSelectedTab(id);
  };
  // const handletypeCheckboxChange =(value)=>{
   
  //   if (selectedCollectionProductTypeOption === value) {
  //     getfilterproducts(selectedOption, priceRange,selectedCollectionOption,"");
  //     setSelectedCollectionProductTypeOption(null);
  //   } else {
  //     getfilterproducts(selectedOption, priceRange,selectedCollectionOption,value);
  //     setSelectedCollectionProductTypeOption(value)
  //   }
  // }

  const handleCheckboxChangeMoblile = (checkboxKey, checkboxValue) => {
    
    setSelectedCheckboxes((prevSelectedCheckboxes) => {
      // Multiple selection for checkboxes other than "category"
      const updatedCheckboxes = { ...prevSelectedCheckboxes };
      if (updatedCheckboxes[checkboxKey]) {
        // Check if the checkboxValue already exists
        const existingValues = updatedCheckboxes[checkboxKey];
        if (existingValues.includes(checkboxValue)) {
          // Remove the checkbox value if already selected
          updatedCheckboxes[checkboxKey] = existingValues.filter(
            (id) => id !== checkboxValue
          );
        } else {
          // Add the checkbox value if not selected
          updatedCheckboxes[checkboxKey] = [...existingValues, checkboxValue];
        }
      } else {
        // Checkbox key does not exist, initialize with an array containing the checkbox value
        updatedCheckboxes[checkboxKey] = [checkboxValue];
      }
    
      return updatedCheckboxes;
    });
  
    };

    const clickOnApplyMobile=()=>{
      localStorage.setItem('FILTER_SESSION', JSON.stringify(selectedCheckboxes));
      window.location.reload()
    }
    const clearFilters =()=>{
      localStorage.removeItem('FILTER_SESSION')
      localStorage.removeItem('SORT_SESSION')
      localStorage.removeItem('RANGE_SESSION')
      window.location.reload()
    }
  return (
    <>
      <BrowserView className="col-lg-3 siderbar-filter">
        <aside>
        <div className="filterbox mb-3">
            <div className="filterbox-title">Shop by Collection</div>
            <div className="filterbox-body filter-common filter-height">
              <div className="filter-list">
                <ul>
                  {loading === true ? (
                    <>
                      {[...Array(8)].map((_, index) => (
                        <li key={index}>
                          <Skeleton variant="text" width={200} />
                        </li>
                      ))}
                    </>
                  ) : (
                    <>
                      {resCollectionList.length > 0 &&
                        resCollectionList.map((value) => (
                     
                           
                              <li key={value.cat_id}>
                                <input
                                  type="checkbox"
                                  onChange={(e) =>
                                    handleCheckboxChange('collection', value.cat_name)
                                  }
                                
                                  checked= {JSON.parse(localStorage.getItem('FILTER_SESSION')) && JSON.parse(localStorage.getItem('FILTER_SESSION'))['collection'] && JSON.parse(localStorage.getItem('FILTER_SESSION'))['collection'].includes(value.cat_name)}
                                />
                                <span>{value.cat_name}</span>
                              </li>
                            
                          
                        ))}
                    </>
                  )}
                </ul>
              </div>
            </div>
          </div>
          <div className="filterbox mb-3">
            <div className="filterbox-title">Shop by Category</div>
            <div className="filterbox-body filter-common filter-height">
              <div className="filter-list">
                <ul>
                  {loading === true ? (
                    <>
                      {[...Array(8)].map((_, index) => (
                        <li key={index}>
                          <Skeleton variant="text" width={200} />
                        </li>
                      ))}
                    </>
                  ) : (
                    <>
                      {aryCategoryList.length > 0 &&
                        aryCategoryList.map((value) => {
                          if (value.children) {
                            return (
                              <li key={value.cat_id}>
                                <input
                                  type="checkbox"
                                  onChange={(e) =>
                                    handleCheckboxChange('category', value.cat_name)
                                  }
                                  checked= {JSON.parse(localStorage.getItem('FILTER_SESSION')) && JSON.parse(localStorage.getItem('FILTER_SESSION'))['category'] && JSON.parse(localStorage.getItem('FILTER_SESSION'))['category'].includes(value.cat_name)}
                                />
                                <span>{value.cat_name}</span>
                              </li>
                            );
                          } else {
                            return (
                              <li key={value.cat_id}>
                                <input
                                  type="checkbox"
                                  onChange={(e) =>
                                    handleCheckboxChange('category', value.cat_name)
                                  }
                                 
                                  checked= {JSON.parse(localStorage.getItem('FILTER_SESSION')) && JSON.parse(localStorage.getItem('FILTER_SESSION'))['category'] && JSON.parse(localStorage.getItem('FILTER_SESSION'))['category'].includes(value.cat_name)}
                                />
                                <span>{value.cat_name}</span>
                              </li>
                            );
                          }
                        })}
                    </>
                  )}
                </ul>
              </div>
            </div>
          </div>
          {/* <div className="filterbox mb-3">
            <div className="filterbox-title">Filter By Price</div>
            <div className="filterbox-body">
              <div style={{ margin: "5px" }}>
                <Slider
                  range
                  min={0}
                  max={100000}
                 value={priceRange}
                  onChange={(range) => rangeHandleChange(range)}
                 
                />
              </div>
              <div>
                <p className="mb-0 tx-12">
                  Selected Price Range: {multiCurrency(priceRange[0])} -
                  {multiCurrency(priceRange[1])}
                </p>
              </div>
            </div>
          </div> */}
          {/* {resProductTypeList && resProductTypeList.length > 0 ? (
  <div className="filterbox mb-3">
    <div className="filterbox-title">Shop by Type</div>
    <div className="filterbox-body filter-common filter-height">
      <div className="filter-list">
        <ul>
          {resProductTypeList.map((value) => (
            <li key={value.tag_id}>
              <input
                type="checkbox"
                className="attributecheckbox"
                onChange={() => handletypeCheckboxChange(value.tag_id)}
                checked={selectedCollectionProductTypeOption === value.tag_id}
              />
              <span>{value.tag_name}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  </div>
) : null} */}


             {resAttributesList.length > 0 &&
            resAttributesList.map((value) => (
              <div className="filterbox mb-3">
                <div className="filterbox-title">{value.attribute_name}</div>
                <div className="filterbox-body filter-common filter-height">
                  <div className="filter-list">
                    <ul>
                      {value.attributeterms &&
                        value.attributeterms.length > 0 &&
                        value.attributeterms.map((subvalue) => (
                          <li key={subvalue.terms_id}>
                            <input
                              type="checkbox"
                              className="attributecheckbox"
                              onChange={() =>
                                handleCheckboxChange(
                                  value.attribute_name,
                                  subvalue.terms_name
                                )
                              }
                              checked={selectedCheckboxes[
                                value.attribute_name
                              ]?.includes(subvalue.terms_name)}
                            />
                            <span>{subvalue.terms_name}</span>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
        </aside>
      </BrowserView>
      <MobileView>
        <Modal
          show={showFilter}
          onHide={handleClose}
          className="filterModal bottom"
        >
          <div className="filterModal-section">
            <div className="filterModal-header">
              <h6 className="tx-14 mb-0">Filters</h6>
              <a href="#"
               onClick={clearFilters} 
               className="tx-12 tx-theme">
                CLEAR ALL
              </a>
            </div>
            <div className="filterModal-body filter-height">
              <div className="filter_tabs">
              <div className="filter_tab">
                  <input
                    type="radio"
                    id="tab-collection"
                    name="tab-group-1"
                    checked={selectedTab === "tab-collection"}
                    onChange={() => handleTabChange("tab-collection")}
                  />
                  <label for="tab-collection">Collection</label>
                  <div className="filter_content">
                    <div className="fiter_content_list">
                      <ul>
                        {resCollectionList.length > 0 &&
                          resCollectionList.map((value) => (
                            <li
                              key={value.cat_id}
                              onChange={(e) =>
                                handleCheckboxChangeMoblile('collection', value.cat_name)
                              }
                            >
                              <span>{value.cat_name}</span>
                              <input
                                type="checkbox"
                                checked= {JSON.parse(localStorage.getItem('FILTER_SESSION')) && JSON.parse(localStorage.getItem('FILTER_SESSION'))['collection'] && JSON.parse(localStorage.getItem('FILTER_SESSION'))['collection'].includes(value.cat_name)}
                           
                              />
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="filter_tab">
                  <input
                    type="radio"
                    id="tab-0"
                    name="tab-group-1"
                    checked={selectedTab === "tab-0"}
                    onChange={() => handleTabChange("tab-0")}
                  />
                  <label for="tab-0">Category</label>
                  <div className="filter_content">
                    <div className="fiter_content_list">
                      <ul>
                        {aryCategoryList.length > 0 &&
                          aryCategoryList.map((value) => (
                            <li
                              key={value.cat_id}
                              onChange={(e) =>
                                handleCheckboxChangeMoblile('category', value.cat_name)
                              }
                            >
                              <span>{value.cat_name}</span>
                              <input
                                type="checkbox"
                                checked= {JSON.parse(localStorage.getItem('FILTER_SESSION')) && JSON.parse(localStorage.getItem('FILTER_SESSION'))['category'] && JSON.parse(localStorage.getItem('FILTER_SESSION'))['category'].includes(value.cat_name)}
                           
                              />
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                </div>
              
                {/* <div className="filter_tab">
                  <input
                    type="radio"
                    id="tab-price"
                    name="tab-group-1"
                    checked={selectedTab === "tab-price"}
                    onChange={() => handleTabChange("tab-price")}
                  />
                  <label for="tab-price">Price</label>
                  <div className="filter_content">
                    <div className="fiter_content_list">
                      <div style={{ margin: "5px" }}>
                        <Slider
                          range
                          min={0}
                          max={100000}
                          value={priceRange}
                          onChange={(range) => rangeHandleChangeMobile(range)}
                          // onAfterChange={(range) =>
                          //   getfilterproducts(selectedOption, range)
                          // }
                        />
                      </div>
                      <div>
                      <p className="mb-0 tx-12">
                  Selected Price Range: {multiCurrency(priceRange[0])} -{" "}
                  {multiCurrency(priceRange[1])}
                </p>
                      </div>
                    </div>
                  </div>
                </div> */}
                
               
                {resAttributesList.length > 0 &&
                  resAttributesList.map((value, index) => (
                    <div className="filter_tab">
                      <input
                        type="radio"
                        id={"tab-" + index + 1}
                        name="tab-group-1"
                        checked={selectedTab === "tab-" + (index + 1)}
                        onChange={() => handleTabChange("tab-" + (index + 1))}
                      />
                      <label for={"tab-" + index + 1}>
                        {value.attribute_name}
                      </label>
                      <div className="filter_content">
                        <div className="fiter_content_list">
                          <ul>
                            {value.attributeterms &&
                              value.attributeterms.length > 0 &&
                              value.attributeterms.map((subvalue) => (
                                <li
                                  key={subvalue.terms_id}
                                  onChange={() =>
                                    handleCheckboxChangeMoblile(
                                      value.attribute_name,
                                      subvalue.terms_name
                                    )
                                  }
                                >
                                  <span>{subvalue.terms_name}</span>
                                  <input
                                    type="checkbox"
                                    checked={selectedCheckboxes[
                                      value.attribute_name
                                    ]?.includes(subvalue.terms_name)}
                                  />
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div className="filterModal-footer">
              <span className="border-right" onClick={handleClose}>
                CLOSE
              </span>
              <span className="tx-theme" onClick={clickOnApplyMobile}>
                APPLY
              </span>
            </div>
          </div>
        </Modal>
      </MobileView>
    </>
  );
}
export default Productlistsidebar;

